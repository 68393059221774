import {Platform} from "react-native";

export const initialState = {
  isLoading: false,
  isShowSplash: false,
  isShowSlideshow: Platform.OS == 'web' ? false : true,
  isShowLogin: Platform.OS == 'web' ? true : false,
  isShowVerification: false,
  isAuthenticated: false,
  isSignout: false,
  user: {}
};

export const reducer = (prevState: any, action: any) => {

  switch (action.type) {

    case 'RESET_TO_INITIALSTATE':
      console.log('authStoreReducer RESET_TO_INITIALSTATE');
      return {
        ...initialState
      };

    case 'SET_LOADING':
      console.log('authStoreReducer SET_LOADING');
      return {
        ...prevState,
        isLoading:  (action.payload ? action.payload : initialState.isLoading)
      };

    case 'SHOW_SLIDESHOW':
      console.log('authStoreReducer SHOW_SLIDESHOW');
      return {
        ...prevState,
        isShowSplash: false,
        isShowSlideshow: true
      };

    case 'SHOW_LOGIN':
      console.log('authStoreReducer SHOW_LOGIN');
      return {
        ...prevState,
        isShowSplash: false,
        isShowSlideshow: false,
        isShowLogin: true,
        isShowVerification: false,
        isAuthenticated: false,
        isSignout: false,
      };

    case 'RESTORE_TOKEN':
      console.log('authStoreReducer RESTORE_TOKEN');
      return {
        ...prevState,
        isShowSplash: false,
        isShowSlideshow: false,
        isShowLogin: false,
        isShowVerification: false,
        isAuthenticated: true,
      };

    case 'SIGN_IN':
      console.log('authStoreReducer SIGN_IN');
      return {
        ...prevState,
        isShowSplash: false,
        isShowSlideshow: false,
        isShowLogin: false,
        isShowVerification: false,
        isAuthenticated: true,
      };

    case 'SIGN_OUT':
      console.log('authStoreReducer SIGN_OUT');
      return {
        ...prevState,
        isShowSplash: false,
        isShowSlideshow: false,
        isShowLogin: true,
        isShowVerification: false,
        isAuthenticated: false,
        isSignout: true,
      };

    case 'SIGN_OUT_AND_SHOW_SLIDESHOW':
      console.log('authStoreReducer SIGN_OUT');
      return {
        ...prevState,
        isShowSplash: false,
        isShowSlideshow: true,
        isShowLogin: false,
        isShowVerification: false,
        isAuthenticated: false,
        isSignout: true,
      };

    case 'SET_USER':
      return {
        ...prevState,
        user:  (action.payload ? action.payload : initialState.user)
      };

  }
};
