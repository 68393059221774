import Constants from "expo-constants";

function getExtra(key: any) {

  // get the manifest from development or EAS versions
  const extra = Constants?.manifest?.extra ?? Constants?.manifest2?.extra?.expoClient?.extra;

  //console.log('Constants', Constants);

  if (extra === undefined) {
    return '';

  }
  else if (key === '') {
    return extra;

  }
  else if (extra[key] === undefined) {
    return '';

  }
  else {

    // get the particular key
    let value = extra[key] ?? '';

    return value;
  }

}

function getManifest(key: any) {

  // get the manifest from development or EAS versions
  const manifest = Constants?.manifest ?? Constants?.manifest2;

  if (manifest === undefined) {
    return '';

  }
  else if (key === '') {
    return manifest;

  }
  else {

    // get the particular key
    if (manifest) {
      // @ts-ignore
      let value = manifest[key] ?? '';
      return value;
    }

    return '';

  }

}

export default {
  getExtra,
  getManifest
}