export const emailValidator = (email: string) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return 'Email cannot be empty.';
  if (!re.test(email)) return 'Ooops! We need a valid email address.';

  return '';
};

export const passwordValidator = (password: string) => {
  if (!password || password.length <= 0) return 'Password cannot be empty.';

  return '';
};

export const nameValidator = (name: string) => {
  if (!name || name.length <= 0) return 'Name cannot be empty.';

  return '';
};

/*
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
 */
export const humanFileSize = (bytes, si) => {
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['KB','MB','GB','TB','PB','EB','ZB','YB']
    : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
};


export const humanDateTime = (isoDateString) => {
  if (!isoDateString || isoDateString.length <= 0) return '';

  return moment.utc(isoDateString).toDate().toLocaleString().replace(/:\d{2}\s/,' ');
}

export const extractNameFromGithubUrl = (url) => {
  if (!url) {
    return null;
  }

  const reg = /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i;
  const components = reg.exec(url);

  if (components && components.length > 5) {
    return components[5];
  }
  return null;
}

export const sortDataByKey = (data, key) => {
  data.sort(function(a, b) {
    return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
  });
  return data;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sleep = (milliseconds) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export const calculateDataUriFileSize = (dataUri) => {

  let result = -1;

  if (dataUri !== undefined && dataUri.substring(0, 4) == 'data') {

    // get the base64 without the data preamble (ie. "data:image/jpeg;base64,sdkfjhsdkjfh")
    var base64str = dataUri.split(',')[1];

    // decode base64
    var decoded = Base64.atob(base64str);

    result = decoded.length
  }

  return result;

}

export const getMimeTypeFromDataUri = (dataUri) => {

  let result = '';

  if (dataUri !== undefined && dataUri.substring(0, 4) == 'data') {

    // get the mime type from data uri (ie. "data:image/jpeg;base64,sdkfjhsdkjfh")
    var mimeType = dataUri.split('data:')[1];

    mimeType = mimeType.split(';base64')[0];

    result = mimeType
  }

  return result;

}

export const uuidv4 = () => {

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });

}

export const unixtimeStamp = () => {
  const ts = Math.round((new Date()).getTime() / 1000);
  //console.log('ts', ts);
  return ts;
}