import {Platform, Linking} from 'react-native';

const openURL = (url) => {

  if(Platform.OS == 'web'){
    window.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }

};

export default {
  openURL
};
