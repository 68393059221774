import React, {useEffect} from 'react';
import {View, StyleSheet, Platform, Linking} from 'react-native';
import {Surface, Switch, Text, Title, TouchableRipple, useTheme,} from 'react-native-paper';
import {PreferencesContext} from '../../../context/preferencesContext';
import ScreenTitle from "../../../components/ScreenTitle";
import ScreenContainerView from "../../../components/ScreenContainerView";
import Button from "../../../components/Button";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import KeyStoreService from "../../../services/KeyStoreService";
import {RootStackScreenProps} from "../../../../types";

import {useAuthStore} from "../../../store/auth/authStore";
import {useDataStore} from "../../../store/data/dataStore";
import {useIsFocused} from "@react-navigation/native";

export default function Profile({ navigation }: RootStackScreenProps<'Profile'>) {

  const theme = useTheme();

  const {signOut, signOutAndShowSlideshow} = useAuthStore();
  const {userProfile, setTrackingMenuId} = useDataStore();

  const { theme: selectedTheme, toggleTheme } = React.useContext( PreferencesContext );

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('Profile[Loading]');
    if (isFocused) {
      console.log('Profile[Focused]');
      setTrackingMenuId('profile');
    }

    return () => {
      console.log('Profile[Unloading]');
    }

  }, [isFocused]);

  const _enableSlideshow = async () => {

    //console.log('Settings::_enableSlideshow');

    await KeyStoreService.setShowSlideshow(true)

    signOutAndShowSlideshow();

  }

  const _onSelectTheme = async () => {

    console.log('Settings::_onSelectTheme');

    //let selected = (appTheme === 'dark' ? 'light' : 'dark');

    // @ts-ignore
    //setAppTheme(selected);

    toggleTheme();

    try {
      //await AsyncStorage.setItem('appTheme', selected);

    } catch (error) {
      //console.error('settings AsyncStorage::setItem error: ' + error.message);
    }

  }

  const renderSettings = () => {
    return (
      <View>

        <TouchableRipple
            onPress={() => navigation.navigate('ChangeUsername')}
        >
            <View style={styles.preference}>
                <Text>Change Username</Text>
                <View pointerEvents="none">
                    <MaterialCommunityIcons name="chevron-right" size={20} style={{color: theme.colors.text}}/>
                </View>
            </View>
        </TouchableRipple>

        <TouchableRipple
          onPress={() => navigation.navigate('ChangePassword')}
        >
          <View style={styles.preference}>
            <Text>Change Password</Text>
            <View pointerEvents="none">
              <MaterialCommunityIcons name="chevron-right" size={20} style={{color: theme.colors.text}}/>
            </View>
          </View>
        </TouchableRipple>

        {Platform.OS !== 'web' &&
        <TouchableRipple onPress={() => _enableSlideshow()}>
            <View style={styles.preference}>
                <Text>Enable Slideshow</Text>
                <View pointerEvents="none">
                    <MaterialCommunityIcons name="chevron-right" size={20} style={{color: theme.colors.text}}/>
                </View>
            </View>
        </TouchableRipple>
        }

        {Platform.OS === 'ios' &&
        <TouchableRipple onPress={() => Linking.openURL('app-settings:')}>
            <View style={styles.preference}>
                <Text>Mobile App Permissions</Text>
                <View pointerEvents="none">
                    <MaterialCommunityIcons name="chevron-right" size={20} style={{color: theme.colors.text}}/>
                </View>
            </View>
        </TouchableRipple>
        }

        <TouchableRipple onPress={_onSelectTheme}>
          <View style={styles.preference}>
            <Text>Dark Theme</Text>
            <View pointerEvents="none">
              <Switch
                ios_backgroundColor="red"
                trackColor={{true: 'green', false: 'red'}}
                value={(selectedTheme == 'dark' ? true : false)}
              />
            </View>
          </View>
        </TouchableRipple>

      </View>
    );
  };

  return (
    <ScreenContainerView scrollable={true}>

      <ScreenTitle
        icon_name="account"
        icon_color={'black'}
        title_text="Profile"
        title_color={'gray'}
        help_text="View and update your settings"
        help_color={'gray'}
        help_text_line2=""
      >
      </ScreenTitle>

      <View>
        <Text>Account Details</Text>
        <Title>{userProfile.first_name} {userProfile.last_name}</Title>
        <Text adjustsFontSizeToFit={true} numberOfLines={1} style={{fontSize: 16, color: theme.colors.text}}>{userProfile.customer_email}</Text>
      </View>

      <Surface style={styles.surfaceContainer}>
        { renderSettings() }
      </Surface>

      <View style={styles.buttonContainer}>
        <Button mode="contained" onPress={signOut} >
          LOGOUT
        </Button>

      </View>

    </ScreenContainerView>
  );
};

const styles = StyleSheet.create({
  preference: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },

  mainText: {},
  mainTextBold: {},
  changePasswordText: {},

  /*
  settingsContainer: {
    maxWidth: 400,
    paddingTop: 30,
    paddingBottom: 30
  },
   */

  buttonContainer: {
    maxWidth: 600
  },

  surfaceContainer: {
    flex: 1,
    padding: Platform.OS === 'web' ? 30 : 10
  }

});

