import React from 'react';
import {StyleSheet, useWindowDimensions, View} from 'react-native';
import {Text, useTheme} from "react-native-paper";
import {MaterialCommunityIcons} from "@expo/vector-icons";

type Props = {
  icon_name: string;
  icon_color: string;
  title_text: string;
  title_color: string;
  help_text: string;
  help_text_line2: string;
  help_color: string;
  children: React.ReactNode;
};

const ScreenTitle = ({ icon_name, icon_color, title_text, title_color, help_text, help_text_line2, help_color, children }: Props) => {

  const theme = useTheme();

  const {fontScale} = useWindowDimensions();

  const styles = makeStyles(fontScale);

  return (
    <View style={styles.container}>
      <View style={styles.topRow}>
        <View style={styles.iconContainer}>
          <MaterialCommunityIcons name={icon_name} size={32} style={[styles.titleIcon, {color: icon_color }]} />
        </View>
        <View style={styles.titleTextContainer}>
          <Text style={styles.titleText}>{title_text}</Text>
        </View>
      </View>
      { help_text.trim().length > 0 &&
      <View style={styles.bottomRow}>
          <View style={styles.iconContainer} />
          <View style={styles.helpTextContainer}>
              <Text style={styles.helpText}>{help_text}</Text>
            {help_text_line2.trim().length > 0 &&
            <Text style={styles.helpTextLine2}>{help_text_line2}</Text>
            }
          </View>
      </View>
      }
      {children}
    </View>
  );

};

const makeStyles = fontScale => StyleSheet.create({
  container: {
    marginBottom: 30
  },
  topRow: {
    flexDirection: "row",
    width: '90%'
  },
  iconContainer: {
    width: 40,
    paddingTop: 4,
    textAlign: "left"
  },
  titleTextContainer: {
  },
  titleIcon: {
    fontSize: 32,
  },
  titleText: {
    fontSize: 32 / fontScale, // divide the font size by the font scale
    fontWeight: "bold",
  },
  bottomRow: {
    flexDirection: "row",
    width: '90%'
  },
  helpTextContainer: {
    paddingLeft: 2,
  },
  helpText: {
    fontSize: 16,
    flexWrap: 'wrap'
  },
  helpTextLine2: {
    fontSize: 16,
  },
});

export default ScreenTitle;
