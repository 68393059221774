import React from 'react';
import Constants from 'expo-constants';
import {Platform} from 'react-native';
import ApiService from './ApiService';
import KeyStoreService from './KeyStoreService';
import ManifestService from "./ManifestService";

const isLoggedIn = async () => {

  //console.log('TimeOffCloudApiService isLoggedIn');

  let result = false;

  let tokens = await KeyStoreService.getTokens();

  let { accessToken = "" } = tokens;

  if ((accessToken) && (typeof accessToken === 'string') && (accessToken.length > 0)) {
    result = true;

  }

  return result;

};

const login = async (email: string, password: string) => {

  //console.log('TimeOffCloudApiService login');

  /*
   * Changes from v1:
   *
   * changed url from /oauth2/token.json to /auth/login.json
   * removed grant_type and client_secret params
   */

  const url = `${ManifestService.getExtra('root')}/auth/login.json`;

  //console.log('TimeOffCloudApiService url next');
  //console.log('TimeOffCloudApiService url', url);

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: `${ManifestService.getExtra('client_id')}`,
      username: email,
      password,
      build_version: ManifestService.getExtra('subver')
    }),
  };

  //console.log('TimeOffCloudApiService apiFetch');

  let data = await ApiService.apiFetch(url, options, false);

  //console.log('TimeOffCloudApiService login data', data);

  let { access_token = false, refresh_token = false } = ApiService.getTokensFromAuthPayload(data);

  if (access_token && refresh_token) {
    await KeyStoreService.setTokens(access_token, refresh_token);
  }

  return data;

};

const forgotPassword = async (email: string) => {

  const url = `${ManifestService.getExtra('root')}/account/password/reset/request.json`;

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email_address: email,
    }),
  };

  return await ApiService.apiFetch(url, options, false);

};

const changePassword = async (old_password: string, new_password: string) => {

  const url = `${ManifestService.getExtra('root')}/account/password.json`;

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      current_password: old_password,
      reset_password: new_password,
      confirm_reset_password: new_password
    }),
  };

  return await ApiService.apiFetch(url, options, true);

};

const changeUsername = async (new_username) => {

  const url = `${ManifestService.getExtra('root')}/account/username.json`;

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_username
    }),
  };

  return await ApiService.apiFetch(url, options, true);

};

const getUser = async () => {

  const url = `${ManifestService.getExtra('root')}/account.json?build_version=${ManifestService.getExtra('subver')}`;

  let options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return await ApiService.apiFetch(url, options, true);

};

const logout = async () => {

  //console.log('TimeOffCloudApiService logout');

  /*
   * Changes from v1:
   *
   * changed url from /oauth2/logout.json to /auth/logout.json
   */

  const url = `${ManifestService.getExtra('root')}/auth/logout.json`;

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  let data = await ApiService.apiFetch(url, options, true);

  // delete the tokens in SecureStorage
  await KeyStoreService.deleteTokens();

  return data;

};

const managePushNotifications = async () => {

  let pnStatus = await KeyStoreService.getPnStatus();

  //console.log('TimeOffCloudApiService::managePushNotifications pnStatus', pnStatus);

  const url = `${ManifestService.getExtra('root')}/pushnotification/manage.json`;

  //console.log('TimeOffCloudApiService::managePushNotifications url', url);

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: `${ManifestService.getExtra('client_id')}`,
      pn_id: pnStatus.token,
      device_type: (Platform.OS === 'ios' ? 0 : 1),
      enabled: pnStatus.granted,
      device_id: pnStatus.device_id,
    }),
  };

  //console.log('TimeOffCloudApiService::managePushNotifications options', options);

  let data = await ApiService.apiFetch(url, options, false);

  //console.log('TimeOffCloudApiService::managePushNotifications response', data);

  return data;

};


const logMessage = async (log_message = '') => {

  const timestamp = new Date().toISOString().slice(0, 19) + 'Z';

  let pnStatus = await KeyStoreService.getPnStatus();

  const url = `${ManifestService.getExtra('root')}/log/access.json`;

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: `${ManifestService.getExtra('client_id')}`,
      device_type: (Platform.OS === 'ios' ? 0 : 1),
      device_id: pnStatus.device_id,
      pn_id: pnStatus.token,
      pn_enabled: (pnStatus.granted ? 1 : 0),
      timestamp,
      app_version: `${Constants.nativeApplicationVersion}`,
      log_message
    }),
  };

  return await ApiService.apiFetch(url, options, true);

};

const upgrades = async () => {

  const timestamp = new Date().toISOString().slice(0, 19) + 'Z';

  const url = `${ManifestService.getExtra('root')}/log/upgrades.json`;

  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: `${ManifestService.getExtra('client_id')}`,
      device_type: (Platform.OS === 'ios' ? 0 : 1),
      timestamp,
      app_version: `${Constants.nativeApplicationVersion}`,
      build_version: `${ManifestService.getExtra('subver')}`,
      env_name: `${ManifestService.getExtra('env_name')}`
    }),
  };

  return await ApiService.apiFetch(url, options, false);

};

export default {
  isLoggedIn,
  login,
  forgotPassword,
  changePassword,
  getUser,
  logout,
  managePushNotifications,
  logMessage,
  upgrades
};

