import React, {useCallback, useEffect, useRef, useState} from "react";
import {FlatList, Platform, Pressable, ScrollView, StatusBar, StyleSheet, TouchableOpacity, View} from 'react-native';
import {Avatar, Surface, Text, useTheme} from 'react-native-paper';
import {RootTabScreenProps} from '../../../../types';
import {DrawerActions, useIsFocused} from "@react-navigation/native";
import {useDataStore} from "../../../store/data/dataStore";
import {MarkedDays} from 'react-native-month';
import {Calendar, DayType, ThemeType} from 'react-native-calendario';
import moment from 'moment';
import Badge from "../../../components/Badge";
import {MaterialCommunityIcons} from "@expo/vector-icons";

const THEME: ThemeType = {
  monthTitleTextStyle: {
    color: '#249BD6',
    fontWeight: 'bold',
    fontSize: 16,
  },
  weekColumnsContainerStyle: {},
  weekColumnStyle: {
    paddingVertical: 10,
  },
  weekColumnTextStyle: {
    color: '#b6c1cd',
    fontSize: 13,
  },
  nonTouchableDayContainerStyle: {},
  nonTouchableDayTextStyle: {
    fontSize: 15,
  },
  startDateContainerStyle: {},
  endDateContainerStyle: {},
  dayContainerStyle: {},
  dayTextStyle: {
    color: '#2d4150',
    fontWeight: '200',
    fontSize: 15,
  },
  dayOutOfRangeContainerStyle: {},
  dayOutOfRangeTextStyle: {},
  todayContainerStyle: {},
  todayTextStyle: {
    color: '#6d95da',
  },
  activeDayContainerStyle: {
    backgroundColor: '#6d95da',
  },
  activeDayTextStyle: {
    color: 'white',
  },
  nonTouchableLastMonthDayTextStyle: {},
  weekendContainerStyle: {
    backgroundColor: 'lightgray'
  },
  weekendTextStyle: {
    color: 'white'
  }
};

const truthyValue = true;

const DISABLED_DAYS = {
  '2019-11-20': truthyValue,
  '2019-11-10': truthyValue,
};

const START_DATE_1 = '2020-01-10';
const END_DATE_1 = '2020-04-15';
const MIN_DATE_1 = '2020-01-02';
const MAX_DATE_1 = '2020-04-20';

const FORMAT = 'YYYY-MM-DD';

const INITIAL_STATE = {
  disableRange: false,
  startDate: moment(START_DATE_1, FORMAT).toDate(),
  endDate: moment(END_DATE_1, FORMAT).toDate(),
  minDate: moment(MIN_DATE_1, FORMAT).toDate(),
  maxDate: moment(MAX_DATE_1, FORMAT).toDate(),
};

const NUMBER_OF_MONTHS = 24;

const markedDays: MarkedDays = {
  '2020-03-11': {
    theme: {
      dayContainerStyle: {
        backgroundColor: 'red'
      }
    }
  },
  '2020-03-12': {
    dots: [
      {
        color: 'red',
        selectedColor: 'green',
      },
      {
        color: 'blue',
        selectedColor: 'yellow',
      },
    ],
  },
};

export default function EmployeeTakeTimeOffScreen({ navigation }: RootTabScreenProps<'EmployeeTakeTimeOffScreen'>) {

  const theme = useTheme();

  const {setTrackingMenuId} = useDataStore();

  const isFocused = useIsFocused();

  const calendarRef = useRef<FlatList>(null);

  const [calendarIndex, setCalendarIndex] = useState(0);

  const [startDate, setStartDate] = useState<Date | undefined>(
    INITIAL_STATE.startDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    INITIAL_STATE.endDate
  );

  useEffect(() => {

    //calendarRef.current?.

  }, []);

  useEffect(() => {
    console.log('EmployeeTakeTimeOffScreen[Loading]');
    if (isFocused) {
      console.log('EmployeeTakeTimeOffScreen[Focused]');
      setTrackingMenuId('mytimeoff-taketimeoff');
    }

    return () => {
      console.log('EmployeeTakeTimeOffScreen[Unloading]');
    }

  }, [isFocused]);

  const handleChangeDate = useCallback(
    (date) => {
      if (startDate) {
        if (endDate) {
          setStartDate(date);
          setEndDate(undefined);
        } else if (date < startDate) {
          setStartDate(date);
        } else if (date > startDate) {
          setEndDate(date);
        } else {
          setStartDate(date);
          setEndDate(date);
        }
      } else {
        setStartDate(date);
      }
    },
    [startDate, endDate]
  );

  const onViewableItemsChanged = useCallback(
    ({ viewableItems, changed }) => {

      if (viewableItems) {
        console.log('viewableItems', viewableItems);
        //console.log('changed', changed);

        console.log('viewableItems[0]', viewableItems[0])

        let { index } = viewableItems[0];

        setCalendarIndex(index);
      }

    }
  );

  const onPressFunction = () => {
    calendarRef.current?.scrollToIndex({index: 0});
  };

  const LeftContent = (props: any) => <Avatar.Icon {...props} icon="folder" />

  const _onTopPressed = () => {
    calendarRef.current?.scrollToIndex({index: 0});
  }

  const _onUpPressed = () => {
    if (calendarIndex > 0) {
      calendarRef.current?.scrollToIndex({index: calendarIndex - 1});
    }
  }

  const _onDownPressed = () => {
    console.log('calendarIndex', calendarIndex);
    if (calendarIndex < NUMBER_OF_MONTHS) {
      calendarRef.current?.scrollToIndex({index: calendarIndex + 1});
    }
  }

  const _onBottomPressed = () => {
    calendarRef.current?.scrollToEnd();
  }

  /*
   The horizontal ScrollView is to prevent the Virtualized list error caused by
   having two vertical scrolling components on the same page (ScrollView and FlatList)
   */

  return (
    <View style={styles.container}>

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: 'white', padding: 5}}
      >

        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
          <Badge
            label_color={'rgba(92,184,92,0.3)'}
            label_text={'Your approved Time Off'}
            label_text_color={'#000000'}
            label_font_weight={'normal'}
          />
          <Badge
            label_color={'#f8c471'}
            label_text={'Your pending Time Off'}
            label_text_color={'#000000'}
            label_font_weight={'normal'}
          />
          <Badge
            label_color={'rgba(91,192,222,0.3)'}
            label_text={'Holidays'}
            label_text_color={'#000000'}
            label_font_weight={'normal'}
          />
          <Badge
            label_color={'#FFFFFF'}
            label_text={'Other people taking time off'}
            label_text_color={''}
            label_font_weight={'normal'}
            dot_color={'#800080'}
            border_color={'lightgray'}
          />
        </View>

        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={{padding: 15}}>{startDate?.toDateString()}</Text>
          <Text style={{padding: 15}}>{endDate?.toDateString()}</Text>
        </View>

        <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Text style={{paddingRight: 15}}>{ calendarIndex }</Text>

          <TouchableOpacity onPress={_onTopPressed} >
            <MaterialCommunityIcons name="chevron-double-up" size={24} style={{color: theme.colors.tocBlue}}/>
          </TouchableOpacity>

          <TouchableOpacity onPress={_onUpPressed} >
            <MaterialCommunityIcons name="chevron-up" size={24} style={{color: theme.colors.tocBlue}}/>
          </TouchableOpacity>

          <TouchableOpacity onPress={_onDownPressed} >
            <MaterialCommunityIcons name="chevron-down" size={24} style={{color: theme.colors.tocBlue}}/>
          </TouchableOpacity>

          <TouchableOpacity onPress={_onBottomPressed} >
            <MaterialCommunityIcons name="chevron-double-down" size={24} style={{color: theme.colors.tocBlue}}/>
          </TouchableOpacity>
        </View>

        <View style={{ paddingTop: StatusBar.currentHeight, flex: 1}} >

          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            style={{ height: 280 }}
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: 'center',
              width: '100%',
            }}
          >

            <Calendar
              ref={calendarRef}
              onPress={handleChangeDate}
              viewableItemsChanged={onViewableItemsChanged}
              startDate={startDate}
              endDate={endDate}
              startingMonth={'2019-11-01'}
              markedDays={markedDays}
              monthHeight={370}
              numberOfMonths={NUMBER_OF_MONTHS}
              initialListSize={4}
              theme={THEME}
              disabledDays={DISABLED_DAYS}
              disableOffsetDays={true}
              firstDayMonday={false}
              calculateMonthHeightDynamically={true}
              showsVerticalScrollIndicator={false}
              renderAllMonths={true}
            />

          </ScrollView>

        </View>

        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>
        <Text>testing scroll</Text>

      </ScrollView>

    </View>
  );
}

/*
      <Pressable style={styles.button} onPress={onPressFunction}>
        <Text style={styles.arrow}>^</Text>
      </Pressable>
 */

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  button: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: 50 / 2,
    backgroundColor: 'pink',
    alignItems: 'center',
    justifyContent: 'center',
    right: 30,
    bottom: 30,
  },
  arrow: {
    fontSize: 48,
  },
});
