/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Slide1: 'slide1',
      Slide2: 'slide2',
      Slide3: 'slide3',
      LoginScreen: 'login',
      ForgotPasswordScreen: 'forgotpassword',
      AppDrawerNavigator: {
        path: 'home',
        screens: {
          AppStackNavigator: {
            screens: {
              EmployeeTakeTimeOffScreen: 'taketimeoff',
              EmployeeCalendarScreen: 'calendar',
              EmployeeReportsScreen: 'reports',
              EmployeeBalancesScreen: 'balances',
              AdminWebContainer: 'admin',
            }
          }
        }
      },
      Modal: 'modal',
      NotFound: '*',
    },
  },
};

export default linking;
