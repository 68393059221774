import React, { memo } from 'react';
import {Image, StyleSheet, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import {Button as PaperButton, Text, useTheme} from 'react-native-paper';
import {MaterialCommunityIcons} from "@expo/vector-icons";

type Props = React.ComponentProps<typeof PaperButton>;

const ButtonGoogleSignin = ({ onPress, children, ...props }: Props) => {

  const theme = useTheme();

  const googleIcon = require('../../assets/images/sign-in/Google__G__Logo.png');

  const {fontScale} = useWindowDimensions();
  const styles = makeStyles(fontScale);

  return (
    <TouchableOpacity onPress={onPress} style={styles.topRow}>
      <View style={styles.iconContainer}>
        <Image
          style={styles.titleIcon}
          source={googleIcon}
        />
      </View>
      <View style={styles.titleTextContainer}>
        <Text style={styles.titleText}>Sign in with Google</Text>
      </View>
    </TouchableOpacity>
  );

};

const makeStyles = fontScale => StyleSheet.create({
  topRow: {
    flexDirection: "row",
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 320,
    height: 44,
    marginVertical: 10,
    borderWidth: 0.5,
    borderRadius: 6,
    backgroundColor: 'white'
  },
  iconContainer: {
    width: 30,
    textAlign: "right"
  },
  titleTextContainer: {
  },
  titleIcon: {
    height: 14, width: '100%', resizeMode: 'contain'
  },
  titleText: {
    fontSize: 17 / fontScale, // divide the font size by the font scale
    color: "black",
    fontWeight: "500"
  },
});

export default memo(ButtonGoogleSignin);
