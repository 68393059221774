import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ScrollView, Platform} from 'react-native';
import {DrawerNavigationProp, DrawerContentComponentProps} from "@react-navigation/drawer";
import {useTheme, Text} from "react-native-paper";
import {PreferencesContext} from "../context/preferencesContext";
import {useAuthStore} from "../store/auth/authStore";
import LogoAndWordmark from "../components/LogoAndWordmark";
import AppSidebarItem from "./AppSidebarItem";
import Divider from "../components/Divider";
import {useDataStore} from "../store/data/dataStore";
import UserRoleService from "../services/UserRoleService";
import AuthService from "../services/AuthService";
import {Auth} from "aws-amplify";

// @ts-ignore
type Props = DrawerContentComponentProps<DrawerNavigationProp>;

export const AppSidebar = (props: Props) => {

  const {userProfile, trackingMenuId, setWebContainerUrl, webContainerLoggedIn} = useDataStore();

  const hasAdminPanelAccess = UserRoleService.hasAdminPanelAccess(userProfile.isAccountOwner, userProfile.isAdmin, userProfile.isManager);

  const [menuMyTimeOffVisible, setMenuMyTimeOffVisible] = useState(false);
  const [menuAdminVisible, setAdminVisible] = useState(false);

  const theme = useTheme();

  const {theme: selectedTheme, toggleTheme} = React.useContext(
    PreferencesContext
  );

  useEffect(() => {

    if (userProfile.isEmployee) {
      setMenuMyTimeOffVisible(true);
    }

    if (hasAdminPanelAccess && !userProfile.isEmployee) {
      setAdminVisible(true);
    }

  }, []);

  const {signOut} = useAuthStore();

  const _onLogoutPressed = async () => {

    console.log('AppStackNavigator _onLogoutPressed dump current session');
    let session = await Auth.currentSession();
    console.log('AuthService.login currentSession', session);

    console.log('AppStackNavigator _onLogoutPressed before logout');
    await AuthService.logout();
    console.log('AppStackNavigator _onLogoutPressed after logout');

    //console.log('AppStackNavigator _onLogoutPressed dump current session');
    //session = await Auth.currentSession();
    //console.log('AuthService.login currentSession', session);

    signOut();
  }

  const _toggleMenuSections = () => {
    setAdminVisible(!menuAdminVisible);
    setMenuMyTimeOffVisible(!menuMyTimeOffVisible);
  }

  /*
   * closed menu = arrowDirection right
   * open menu = arrowDirection up
   */

  const _onPasswordlessPressed = async () => {

    try {
      let passwordlessUrl = await AuthService.getPasswordlessUrl('/admin/employee/employee_list');
      console.log('LoginScreen passwordlessUrl', passwordlessUrl);

      setWebContainerUrl(passwordlessUrl);
    }
    catch(e) {
      console.log('LoginScreen passwordless error', e);
    }

  }

  const _onAdminWebMenuItemPressed = async (path: string) => {

    if (webContainerLoggedIn) {

      let loggedInUrl = 'https://portal-staging.timeoffcloud.net/timeoff' + path;

      console.log('AppSidebar loggedInUrl', loggedInUrl);

      setWebContainerUrl(loggedInUrl);

    }
    else {

      try {
        let passwordlessUrl = await AuthService.getPasswordlessUrl('/timeoff' + path);
        console.log('AppSidebar passwordlessUrl', passwordlessUrl);

        setWebContainerUrl(passwordlessUrl);
      }
      catch(e) {
        console.log('AppSidebar passwordless error', e);
      }

    }

  }

  /*
          <Text>{ webContainerLoggedIn ? 'Web Logged In' : 'Web Logged Out'}</Text>
   */

  return (
    <View style={{flex: 1}}>

      <View style={{alignItems: 'center', paddingBottom: 10}}>
        <LogoAndWordmark dark={true} width={180} height={40}/>
      </View>

      <ScrollView>

        {(userProfile.isEmployee) &&
        <>

          {(hasAdminPanelAccess == true) &&
          <AppSidebarItem text={'My Time Off'} iconName={'account-check'} showArrow={true}
                            arrowDirection={(menuMyTimeOffVisible ? 'up' : 'right')}
                            onPress={_toggleMenuSections}/>
          }

          {(hasAdminPanelAccess == false) &&
          <AppSidebarItem id={'mytimeoff'}
                          text={'My Time Off'} iconName={'account-check'}
                            navScreenName={'MyTimeOff'} navTabName={'EmployeeTakeTimeOffScreen'}
                            onPress={() => {}} />
          }

          {(menuMyTimeOffVisible == true) &&
          <>
              <AppSidebarItem id={'mytimeoff-taketimeoff'}
                                text={'Take Time Off'} iconName={'alarm-plus'} iconColor={'gray'} inset={true}
                               navScreenName={'MyTimeOff'} navTabName={'EmployeeTakeTimeOffScreen'}
                               onPress={() => {}} />
              <AppSidebarItem id={'mytimeoff-calendar'}
                                text={'Calendar'} iconName={'calendar-month'} iconColor={'gray'} inset={true}
                               navScreenName={'MyTimeOff'} navTabName={'EmployeeCalendarScreen'}
                               onPress={() => {}} />
              <AppSidebarItem id={'mytimeoff-reports'}
                                text={'Reports'} iconName={'file-chart-outline'} iconColor={'gray'} inset={true}
                               navScreenName={'MyTimeOff'} navTabName={'EmployeeReportsScreen'}
                               onPress={() => {}} />
              <AppSidebarItem id={'mytimeoff-balances'}
                                text={'Balances'} iconName={'chart-bar'} iconColor={'gray'} inset={true}
                               navScreenName={'MyTimeOff'} navTabName={'EmployeeBalancesScreen'}
                               onPress={() => {}} />
          </>
          }
        </>
        }

        {(hasAdminPanelAccess == true) &&
        <>

          {(userProfile.isEmployee == true) &&
          <AppSidebarItem text={'Admin'} iconName={'office-building-cog-outline'} showArrow={true}
                            arrowDirection={(menuAdminVisible ? 'up' : 'right')}
                            onPress={_toggleMenuSections}/>
          }

          {(userProfile.isEmployee == false) &&
          <AppSidebarItem text={'Admin'} iconName={'office-building-cog-outline'}
                           onPress={() => {}} />
          }

          {(menuAdminVisible == true) &&
          <>
              <AppSidebarItem id={'admin-dashboard'}
                              text={'Dashboard'} iconName={'home'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/dashboard')}/>
              <AppSidebarItem id={'admin-inbox'}
                              text={'Inbox'} iconName={'inbox'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/mailbox/inbox')}/>
              <AppSidebarItem id={'admin-notices'}
                              text={'Notices'} iconName={'bullhorn'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/notice/manage_notice')}/>
              <AppSidebarItem id={'admin-timeoffrequests'}
                              text={'Time Off Requests'} iconName={'tray-full'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/timeoffrequests')}/>
              <AppSidebarItem id={'admin-employees'}
                              text={'Employees'} iconName={'account-group'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/employee/employee_list')}/>
              <AppSidebarItem id={'admin-reports'}
                              text={'Reports'} iconName={'file-chart'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/reports/get_employee_report')}/>
              <AppSidebarItem id={'admin-settings'}
                              text={'Settings'} iconName={'cog-outline'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              onPress={() => _onAdminWebMenuItemPressed ('/admin/settings/general_settings')}/>
              <AppSidebarItem id={'admin-logout'}
                              text={'Web Logout'} iconName={'logout'} iconColor={'gray'} inset={true}
                              navScreenName={'AdminWebContainer'}
                              navParams={{ url: 'https://portal-staging.timeoffcloud.net/timeoff/logout/logouttenant'}}
                              onPress={() => {}}/>
          </>
          }
            <Divider text={''} textSize={12}/>
        </>
        }

        <AppSidebarItem id={'profile'} text={'Profile'} iconName={'account'}
                         navScreenName={'Profile'}
                         onPress={() => {}} />
        <AppSidebarItem id={'about'} text={'About'} iconName={'information-outline'}
                         navScreenName={'About'}
                         onPress={() => {}} />

        <AppSidebarItem id={'logout'} text={'Logout'} iconName={'logout'}
                         onPress={_onLogoutPressed}/>

      </ScrollView>

    </View>

  );

}

const styles = StyleSheet.create({
  spaceItem: {
    paddingTop: 30,
    paddingLeft: 20
  }
});