/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

/*
 * From the Amplify setup
 *
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:355056c9-afe9-464d-96e0-960e75f22bc9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VaIa3unRc",
    "aws_user_pools_web_client_id": "27ntde7v1l5se5bvsp9sovbac1",
    "oauth": {}
};

export default awsmobile;
*/

/*
 * https://medium.com/alturasoluciones/react-native-signin-and-signup-with-aws-cognito-2a285599b7c4
 *
 * Values From Sean
 *
const awsConfig = {
    identityPoolId: '',
    region: 'us-east-1',
    userPoolId: 'us-east-1_43URZalla',
    userPoolWebClientId: '7hhbsl42q5u5le3uflg0lknlu4'
};

export default awsConfig;
 */

/*
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_43URZalla",
    "aws_user_pools_web_client_id": "7hhbsl42q5u5le3uflg0lknlu4",
    "oauth": {}
};

export default awsmobile;
*/

/*
 * This works best I am getting errors
 *
const awsConfig = {
    identityPoolId: '',
    region: 'us-east-1',
    userPoolId: 'us-east-1_43URZalla',
    userPoolWebClientId: '46vstt830otceilidgema33sij'
};

export default awsConfig;
*/

/* JEC test user pool from 4/27/2022
*
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_43URZalla",
    "aws_user_pools_web_client_id": "46vstt830otceilidgema33sij",
    "oauth": {}
};
*/

/* JEC test user pool from 4/27/2022 (JEC username/password provider)
*
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_axXJDlJYx",
    "aws_user_pools_web_client_id": "67uk8oqfb5r3fm85g6sc9o01nt",
    "oauth": {}
};
*/

/* Sean test for Okta (SDH SAML provider)
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GzuyS7Ca7",
    "aws_user_pools_web_client_id": "30rlhphps7etildlnqv5297aqv",
    oauth: {
        domain: 'toc-dev-sh-2.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://portal-dev.timeoffcloud.net/',
        redirectSignOut: 'https://portal-dev.timeoffcloud.net/',
        responseType: 'code'
    }
};
*/


/* Staging */
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3AnxkEQRy",
    "aws_user_pools_web_client_id": "eacgv6kk2f0241islavmqqguu",
    oauth: {
        domain: 'toc-staging.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'exp://192.168.0.121:19000/',
        redirectSignOut: 'exp://192.168.0.121:19000/',
        /*
        redirectSignIn: 'http://localhost:19006/',
        redirectSignOut: 'http://localhost:19006/',
        redirectSignIn: 'https://portal-staging.timeoffcloud.net/',
        redirectSignOut: 'https://portal-staging.timeoffcloud.net/',*/
        responseType: 'code'
    }
};

export default awsmobile;
