import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Text, Title, useTheme} from 'react-native-paper';
import { RootStackScreenProps } from '../../../types';
import LottieView from "lottie-react-native";
import React, {useEffect, useRef} from "react";

export default function Slide1({ navigation }: RootStackScreenProps<'Slide1'>) {

  const theme = useTheme();

  const animation = useRef(null);

  useEffect(() => {
    console.log('Loading[Slide1]');
  }, []);

  const _onForwardPressed = () => {
    navigation.navigate('Slide2');
  }

  return (
    <View style={[styles.container, {backgroundColor: theme.colors.tocBlue}]}>
      <Title style={{color: 'white'}}>Check Your Balances</Title>

      <LottieView
        autoPlay
        ref={animation}
        style={{
          width: 300,
          height: 300,
          backgroundColor: theme.colors.tocBlue,
        }}
        // Find more Lottie files at https://lottiefiles.com/featured
        source={require('../../../assets/lottie/100641-graph-lottie-animation.json')}
      />

      <TouchableOpacity onPress={_onForwardPressed} style={{ paddingTop: 30 }}>
        <Text>Go Forward</Text>
      </TouchableOpacity>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
