import React, { memo } from 'react';
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native';
import { useTheme, Text } from 'react-native-paper';
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {useDataStore} from "../store/data/dataStore";
import * as NavigationService from "../services/NavigationService";

type Props = {
  id?: string;
  inset?: boolean;
  iconName: any;
  iconColor?: string;
  text: string;
  iconSize?: number;
  textSize?: number;
  showArrow?: boolean;
  arrowDirection?: string;
  active?: boolean;
  navScreenName?: string;
  navTabName?: string;
  navParams?: object;
  onPress: () => void;
};

const AppSidebarItem = ({ id='', text='', iconName='question', iconColor='', iconSize=20,
                            textSize=16, inset=false, showArrow=false, arrowDirection='right',
                            active=false, navScreenName='', navTabName='', navParams={},
                            onPress }: Props) => {

  const {setWebContainerUrl, trackingMenuId, setTrackingMenuId} = useDataStore();

  const theme = useTheme();

  //setTimeout(() => { props.navigation.dispatch(DrawerActions.closeDrawer()); }, 30,);

  const _onPress = () => {

    console.log('******** id', id);
    console.log('******** navScreenName', navScreenName);
    console.log('******** navParams', navParams);

    if (navScreenName == '') {
      console.log('AppSidebarItem nav screen name not provided, track only');
      onPress();
      return;
    }

    // Check to see if the navParams contain a URL

    // @ts-ignore
    let {url = ''} = navParams;

    console.log('******** url', url);

    if (url) {
      setWebContainerUrl(url);
    }

    // if the menu id prop prefix matches the trackingMenuId from data store
    if (trackingMenuId.slice(0, 5) == id.slice(0,5)) {

      console.log('AppSidebarItem standard stack navigation');

      // if the admin webcontainer is already loaded
      if (trackingMenuId.slice(0, 5) == 'admin') {
        console.log('AppSidebarItem standard stack navigation, webcontainer url', url);
        NavigationService.closeDrawer();

      }
      else {
        // standard stack navigation
        console.log('AppSidebarItem standard stack navigation, navigate');
        NavigationService.navigate(navScreenName, { screen: navTabName, ...navParams });
        setTrackingMenuId(id);
      }

    }
    else {
      console.log('AppSidebarItem reset stack navigation');

      // reset stack navigation
      NavigationService.reset(navScreenName, { screen: navTabName, ...navParams });
      NavigationService.closeDrawer();
      setTrackingMenuId(id);
    }

    onPress();

  }

  return (
    <TouchableOpacity
      onPress={_onPress}
      style={styles.wrapper}
    >
      <MaterialCommunityIcons
        name={iconName}
        size={iconSize}
        color={ (iconColor.length > 0) ? iconColor : theme.colors.tocBlue}
        style={[styles.iconLeft, { minWidth: iconSize+5, marginLeft: (inset) ? iconSize+4 : 4, marginRight: (inset) ? 4 : 10 }]}
      />
      <Text style={{ fontSize: (inset) ? textSize-2 : textSize, fontWeight: (active || (id != '' && trackingMenuId == id) ? 'bold' : 'normal') }}>
        { text }
      </Text>
      { showArrow==true &&
        <MaterialCommunityIcons
          name={'chevron-' + arrowDirection}
          size={iconSize}
          color={ (iconColor.length > 0) ? iconColor : theme.colors.tocBlue}
          style={styles.iconRight}
        />
      }
    </TouchableOpacity>
  );

};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 12,
    paddingHorizontal: 6,
    flexDirection: "row",
    alignItems: "center"
  },
  iconLeft: {
  },
  text: {

  },
  iconRight: {
    marginLeft: 10
  }
});

export default memo(AppSidebarItem);
