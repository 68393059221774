import {CommonActions, createNavigationContainerRef, DrawerActions} from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef()

export function navigate(name, params = {}) {
  if (navigationRef.isReady()) {

    let route = (params === {} ? {name} : {name, params});

    navigationRef.dispatch(
      CommonActions.navigate(
        route
      )
    );

  }

}

// reset the Stack Navigator and navigate to a route
export function reset(name, params = {}) {
  if (navigationRef.isReady()) {

    let route = (params === {} ? {name} : {name, params});

    navigationRef.dispatch(
      CommonActions.reset(
        {
          index: 0,
          routes: [route],
          //key: null
        }
      )
    );

  }

}

export function closeDrawer() {

  if (navigationRef.isReady()) {
    navigationRef.dispatch(DrawerActions.closeDrawer());
  }

}