import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {RootTabScreenProps} from "../../../../types";
import React, {useEffect} from "react";
import {useDataStore} from "../../../store/data/dataStore";
import {useFocusEffect, useIsFocused} from "@react-navigation/native";

export default function EmployeeBalancesScreen({ navigation }: RootTabScreenProps<'EmployeeBalancesScreen'>) {

  const {setTrackingMenuId} = useDataStore();

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('EmployeeBalancesScreen[Loading]');
    if (isFocused) {
      console.log('EmployeeBalancesScreen[Focused]');
      setTrackingMenuId('mytimeoff-balances');
    }

    return () => {
      console.log('EmployeeBalancesScreen[Unloading]');
    }

  }, [isFocused]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Balances Screen</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
