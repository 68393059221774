import { MD3LightTheme as DefaultTheme, MD3DarkTheme as DarkTheme } from 'react-native-paper';

// #f8f9fe

export const lightTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#00A7F7',
    primaryLight: '#00A7F7',
    tocBackground: "#f8f9fe",
    surfaceVariant: "#ddeff8",
    tocBlue: "#249BD6",
  },
  appName: 'Time Off Cloud'
};

export const darkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    primary: '#00A7F7',
    primaryLight: '#00A7F7',
    accent: "#a5a5a5",
    tocBlue: "#249BD6",
  },
  appName: 'Time Off Cloud'
};

