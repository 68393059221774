import React, {useEffect} from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import {Surface, Text, useTheme} from 'react-native-paper';
import ScreenContainerView from "../../../components/ScreenContainerView";
import ScreenTitle from "../../../components/ScreenTitle";
import {RootStackScreenProps} from "../../../../types";
import {useIsFocused} from "@react-navigation/native";

import {useDataStore} from "../../../store/data/dataStore";
import ManifestService from "../../../services/ManifestService";
import LinkingService from "../../../services/LinkingService";

export default function About({ navigation }: RootStackScreenProps<'About'>) {

  const theme = useTheme();

  const {setTrackingMenuId} = useDataStore();

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('About[Loading]');

    if (isFocused) {
      console.log('About[Focused]');
      setTrackingMenuId('about');
    }

    return () => {
      console.log('About[Unloading]');
    }

  }, [isFocused]);

  return (
    <ScreenContainerView scrollable={true}>

      <ScreenTitle
        icon_name="information-outline"
        icon_color={'black'}
        title_text="About"
        title_color="black"
        help_text="Time Off Cloud is proudly made in Bensalem, USA"
        help_text_line2=""
        help_color={'gray'}
      >
        <View/>
      </ScreenTitle>

      <Surface style={styles.surfaceContainer}>
        <Text style={{color: theme.colors.tocBlue, fontWeight: 'bold', alignSelf: "center"}}
              onPress={() => LinkingService.openURL('https://www.timeoffcloud.com')}>
          https://www.timeoffcloud.com
        </Text>

        <Text style={{color: theme.colors.tocBlue, alignSelf: "center", paddingTop: 20}}
              onPress={() => LinkingService.openURL('https://help.timeoffcloud.com/')}>
          Knowledge Base
        </Text>

        <Text style={{color: theme.colors.tocBlue, alignSelf: "center", paddingTop: 20}}
              onPress={() => LinkingService.openURL('https://www.timeoffcloud.com/terms/')}>
          Terms and Conditions
        </Text>

        <Text style={{color: theme.colors.tocBlue, alignSelf: "center", paddingTop: 20}}
              onPress={() => LinkingService.openURL('https://www.timeoffcloud.com/privacy/')}>
          Privacy Policy
        </Text>

        <Text style={{color: theme.colors.tocBlue, alignSelf: "center", paddingTop: 20}}
              onPress={() => navigation.navigate('Licensing')}>
          Copyright & Licenses
        </Text>

      </Surface>

      <Text style={{alignSelf: "center", paddingTop: 20}}>
        Version {ManifestService.getExtra('subver')}
      </Text>

    </ScreenContainerView>
  );
};

const styles = StyleSheet.create({
  mainTextBold: {
    paddingTop: 12,
    fontWeight: "bold",
  },
  mainText: {
    paddingTop: 2,
  },
  legalStuff: {
    alignSelf: "center",
    paddingTop: 20,
  },

  surfaceContainer: {
    flex: 1,
    padding: Platform.OS === 'web' ? 30 : 10
  }

});
