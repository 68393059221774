import React, { memo } from 'react';
import {StyleSheet, KeyboardAvoidingView, View, ScrollView} from 'react-native';
import Footer from '../components/Footer';

// @ts-ignore
import background_dots from "../../assets/images/background_dot.png";
import {useTheme} from "react-native-paper";

type Props = {
  children: React.ReactNode;
};

const Background = ({ children }: Props) => {

  const theme = useTheme();

  return (
    <View style={[styles.background, { backgroundColor: theme.colors.tocBackground }]}>
      <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <KeyboardAvoidingView behavior="padding" style={styles.container}>
          {children}
        </KeyboardAvoidingView>
      </ScrollView>
      <Footer>Meta Section, LLC | Copyright 2022</Footer>
    </View>
  );

  /*
  return (
    <View style={styles.background}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <KeyboardAvoidingView behavior="padding" style={styles.container}>
          {children}
        </KeyboardAvoidingView>
      </ScrollView>
      <Footer>Meta Section, LLC | Copyright 2022</Footer>
    </View>
  );
  */

};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    // width: null
  },
  container: {
    flex: 1,
    padding: 10,
    width: '100%',
    maxWidth: 340,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export default Background;
