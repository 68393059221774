import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Text} from 'react-native-paper';
import {RootStackScreenProps} from '../../../../types';

import {WebContainer} from '../../../components/WebContainer';
import {useDataStore} from "../../../store/data/dataStore";
import {useIsFocused} from "@react-navigation/native";
import {useEffect} from "react";
import {Router} from "tiny-request-router";
import AuthService from "../../../services/AuthService";

// <WebContainer target="https://balkan.app/OrgChartJS" />
// <WebContainer target="https://www.timeoffcloud.com" />
// <WebContainer target="https://dev.timeoffcloud.net/index.php" />

const router = new Router();

/*
  .get('/', 'admin-login')
  .get('/(.*)/login', 'admin-login')
  .get('/logout', 'admin-logout')
  .get('/logout/logout', 'admin-logout')
  .get('/(.*)/admin/dashboard', 'admin-dashboard')
  .get('/(.*)/admin/mailbox/inbox', 'admin-inbox')
  .get('/(.*)/admin/notice/manage_notice', 'admin-notices')
  .get('/(.*)/admin/timeoffrequests', 'admin-timeoffrequests')
  .get('/(.*)/admin/employee/employee_list', 'admin-employees')
  .get('/(.*)/admin/reports/get_employee_report', 'admin-reports')
  .get('/(.*)/admin/settings/general_settings', 'admin-settings')
  .get('/(.*)/employee/dashboard', 'employee-dashboard');
 */

router
  .get('/(.*)/admin/dashboard', 'admin-dashboard')
  .get('/(.*)/admin/mailbox/inbox', 'admin-inbox')
  .get('/(.*)/admin/notice/manage_notice', 'admin-notices')
  .get('/(.*)/admin/timeoffrequests', 'admin-timeoffrequests')
  .get('/(.*)/admin/employee/employee_list', 'admin-employees')
  .get('/(.*)/admin/reports/get_employee_report', 'admin-reports')
  .get('/(.*)/admin/settings/general_settings', 'admin-settings')
  .get('/(.*)/ticket/(.*)', 'admin-ticket');

export default function AdminWebContainer({ route, navigation }: RootStackScreenProps<'AdminWebContainer'>) {

  const {setTrackingUri, setTrackingMenuId, webContainerUrl, setWebContainerUrl, setWebContainerLoggedIn} = useDataStore();

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('WebContainer[Loading]');

    if (isFocused) {
      console.log('WebContainer[Focused]');

      // @ts-ignore
      //const {params: {url}} = route;
      //console.log('url', url);

      //_trackMenuId(route.params.url);

    }

    return () => {
      console.log('WebContainer[Unloading]');
    }

  }, [isFocused]);

  const _onWebNavigation = (page: string) => {

    console.log('_onWebNavigation', page);

    _trackMenuId(page);

  }

  const _trackMenuId = async (page: string) => {

    console.log('_trackMenuId(page)', page);

    setTrackingUri(page);

    const match = router.match('GET', page);
    console.log('Router match', match);

    if (match) {

      // @ts-ignore
      const { handler = '' } = match;

      setTrackingMenuId(handler);
      setWebContainerLoggedIn(true);

    }
    else {
      setWebContainerLoggedIn(false);
    }

  }

  /*
   * WebContainer
   *
   * *****************
   * Props
   * *****************
   *
   * The target prop will contain the URL of the passwordless login.
   *
   * The initialAuthCode prop contains the authCode that will be passed to the passwordless login page.
   *
   * fetchAuthCode is a function prop that will allow the WebContainer to fetch a new authCode if the session timed out.
   *
   * onWebNavigation is a function prop that will receive all of the web page names as web navigation occurs.
   *
   * *****************
   * Logic
   * *****************
   *
   * The WebContainer will load the target.
   *
   * When the target has loaded, it will post a message to the WebContainer with the page name.
   *
   * If the user has not logged in and the page name (target) is the passwordless page then the
   * WebContainer will send the authCode to the web page.
   *
   * The web page will receive the authCode and then use $.ajax() to login.  This will generate the necessary cookies.
   *
   * Upon successful login, the passwordless page will post a message to the WebContainer with the loggedIn status.
   *
   * Each time that the web page changes, the onWebNavigation function will be called.  The WebContainer will
   * check to make sure that the session has not expired.  If the session expired, the WebContainer will fetch
   * a new authCode with fetchAuthCode and reload the target.
   *
   * If the web page user has logged out, we may want to logout the app user session (ie. clear tokens).
   *
   */

  //         target="https://dev.timeoffcloud.net/index.php"

  //         target={route.params.url}

  /*
  <Text>{webContainerUrl}</Text>
   */


  return (
    <View style={{ flex: 1 /*, backgroundColor: 'gray', padding: 20*/ }}>
      <WebContainer
          target={webContainerUrl}
          onWebNavigation={_onWebNavigation}
      />
    </View>
  );

  /*
  const _onBackPressed = () => {
    navigation.goBack();
  }

  return (
    <View style={styles.container}>

      <Text style={styles.title}>AdminWebContainer</Text>

      <TouchableOpacity onPress={_onBackPressed} style={{ paddingTop: 30 }}>
        <Text>Go Back</Text>
      </TouchableOpacity>

    </View>
  );
   */

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
