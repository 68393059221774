import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import Toast from "react-native-toast-message";

const IDP_USERPASS = 'userpass';
const IDP_GOOGLE = 'google';
const IDP_APPLE = 'signinwithapple';
const IDP_CUSTOM_SAML = 'saml';
const IDP_CUSTOM_OIDC = 'oidc';
const IDP_UNDEFINED = '';

const idpLookup = async (username: string) => {

  console.log('idpLookup');

  let url = 'https://51r3rn53nb.execute-api.us-east-1.amazonaws.com/staging';

  /*
      Accept: 'application/json',
      'Content-Type': 'application/json',
   */
  let options = {
    method: 'POST',
    body: JSON.stringify({
      email: username
    }),
  };

  let result = {
    idp: '',
    idp_name: ''
  };

  await fetch(url, options)
    .then((response) => {
      console.log('AuthService idpLookup, status:', response.status);
      return response;

    })
    .then(response => response.json())
    .then((response) => {

      console.log('AuthService idpLookup, response', response);

      let { idp = '', idp_name = '' } = response;
      console.log('AuthService idpLookup, idp:', idp);
      console.log('AuthService idpLookup, idp_name:', idp_name);

      result.idp = idp;
      result.idp_name = idp_name;

    })
    .catch(err => {
      console.log('AuthService login(), error:', err);

    });

  return result;

}

const login = async (idp: string, idp_name: string, username: string, password: string) => {

  try {

    switch (idp) {

      case IDP_USERPASS:
        await Auth.signIn(username, password);
        break;

      case IDP_GOOGLE:
        await Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google
        });
        break;

      case IDP_APPLE:
        await Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Apple
        });
        break;

      case IDP_CUSTOM_SAML:
      case IDP_CUSTOM_OIDC:
        await Auth.federatedSignIn({customProvider:idp_name});
        break;

      case IDP_UNDEFINED:
        break;

    }
    console.log('AuthService.login, federatedSignIn, finished');

    /*
    let session = null;

    await Auth.currentSession().then((cognitoUserSession) => {
        session = cognitoUserSession;
        console.log('AuthService.login currentSession, session', session);
      })
      .catch((e) => {
        console.log('AuthService.login currentSession, catch', e);
      });
    console.log('AuthService.login, currentSession, finished');

    if (session) {

      let currentAuthUser = await Auth.currentAuthenticatedUser();
      console.log('AuthService.login currentAuthUser', currentAuthUser);

      return session;
    }
     */

  } catch (err: any) {

    console.log('Auth.signIn err', err.message);

    //setMessage(err.code);

    if (err.code === 'UserNotConfirmedException') {
      console.log('Account not verified yet')
    } else if (err.code === 'PasswordResetRequiredException') {
      console.log('Existing user found. Please reset your password')
    } else if (err.code === 'NotAuthorizedException') {
      console.log('Forgot Password?')
    } else if (err.code === 'UserNotFoundException') {
      console.log('User does not exist!')
    }

    throw(err.message);

  }

};

const getOtac = async () => {

  let session = await Auth.currentSession();
  let idToken = await session.getIdToken();

  console.log('currentSession', session);

  console.log('jwtToken', idToken.getJwtToken());

  let url = 'https://wvsgtulur2.execute-api.us-east-1.amazonaws.com/staging';

  /*
      Accept: 'application/json',
      'Content-Type': 'application/json',
   */
  let options = {
    method: 'POST',
    headers: {
      'Authorization': idToken.getJwtToken()
    }
  };

  let otac = '';

  await fetch(url, options)
    .then((response) => {
      console.log('AuthService Get OTAC, status:', response.status);
      return response;

    })
    .then(response => response.json())
    .then((response) => {
      let { toc_otac = '' } = response;
      console.log('AuthService Get OTAC, toc_otac:', toc_otac);
      otac = toc_otac;

    })
    .catch(err => {
      otac = '';
      console.log('AuthService login(), error:', err);

    });

  return otac;
}

// redirect_path = '/admin/employee/employee_list'
const getPasswordlessUrl = async (redirect_path: string) => {

  // *JEC* TODO build this string properly

  let otac = await getOtac();

  if (otac.length == 0) {
    throw 'Unable to fetch OTAC';
  }

  return 'https://portal-staging.timeoffcloud.net/passwordless/login?otac=' + otac + '&redirect=' + redirect_path;

}

const logout = async () => {

  // *JEC* TODO add global option to signOut based on this example... https://blog.logrocket.com/aws-amplify-react-native-tutorial-with-examples/

  await Auth.signOut({ global: true }).then(() => {
    console.log('AuthService.logout, success');
  })
  .catch((e) => {
    console.log('AuthService.logout, catch', e);
  });
  console.log('AuthService.logout, finished');
}

export default {
  IDP_USERPASS,
  IDP_GOOGLE,
  IDP_APPLE,
  IDP_CUSTOM_SAML,
  IDP_CUSTOM_OIDC,
  IDP_UNDEFINED,
  idpLookup,
  login,
  getPasswordlessUrl,
  logout
}
