import React, {Fragment, useEffect, useState} from 'react';
import {View, StyleSheet, Platform, } from 'react-native';
import {Surface, Text, TextInput, Title, useTheme,} from 'react-native-paper';
import ScreenTitle from "../../../components/ScreenTitle";
import ScreenContainerView from "../../../components/ScreenContainerView";
import Button from "../../../components/Button";
import {Formik} from "formik";
import * as yup from "yup";
import ApiService from "../../../services/ApiService";
import Toast from "react-native-toast-message";

import {useAuthStore} from "../../../store/auth/authStore";
import {useDataStore} from "../../../store/data/dataStore";
import {RootStackScreenProps} from "../../../../types";
import {PreferencesContext} from "../../../context/preferencesContext";
import {useIsFocused} from "@react-navigation/native";
import TimeOffCloudApiService from "../../../services/TimeOffCloudApiService";
import AuthService from "../../../services/AuthService";

export default function ChangeUsername({ navigation }: RootStackScreenProps<'ChangeUsername'>) {

  const theme = useTheme();

  const {signOut} = useAuthStore();
  const {userProfile, setTrackingMenuId} = useDataStore();

  const [loading, setLoading] = useState(false);

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('ChangeUsername[Loading]');
    if (isFocused) {
      console.log('ChangeUsername[Focused]');
      setTrackingMenuId('profile');
    }

    return () => {
      console.log('ChangeUsername[Unloading]');
    }

  }, [isFocused]);

  const _onRequestChangeUsernamePressed = async (values, { setErrors }) => {

    let { email } = values;

    try {

      //console.log('ChangeUsername _onLoginPressed');

      setLoading(true);

      const apiResponse = await TimeOffCloudApiService.changeUsername(email);

      let { _connected = false, _authorized = false, message = '', _errorMessage = '', _errorMessages = false, status = false } = apiResponse;

      if (status) {

        //console.log('ChangeUsername apiResponse', apiResponse);

        Toast.show({ text1: 'Your username has been changed.  Please login again.', text2: '(Swipe to dismiss message)', type: 'success', autoHide: true });

        await AuthService.logout();
        signOut();

      }
      else {

        // field level and toast messages
        let allMessages = ApiService.getMessageInfoFromPayload(message, _errorMessage, _errorMessages);
        setErrors(allMessages.msgs);
        Toast.show({ text1: allMessages.msg, type: 'error', autoHide: true });

      }

    } catch (err) {
      Toast.show({ text1: 'Unable to request username update. Please try again later.', type: 'error', autoHide: true });
    }

    setLoading(false);

  };

  return (
    <ScreenContainerView scrollable={true}>

      <ScreenTitle
        icon_name="account-outline"
        icon_color={'black'}
        title_text="Change Username"
        title_color="black"
        help_text="Please enter a valid email address."
        help_text_line2=""
        help_color={'gray'}
      >
        <View />
      </ScreenTitle>

      <Surface style={styles.surfaceContainer}>

        <Title>Current Username</Title>
        <Text style={{ fontSize: 16, color: theme.colors.text }}>{userProfile.customer_email}</Text>

        <Title style={{marginTop: 20}}>New Username</Title>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={_onRequestChangeUsernamePressed}
          validationSchema={yup.object().shape({
            email: yup.string().email('Please enter a valid email address.').required('Email address is required.')
          })}
        >

          {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
            <Fragment>

              <View style={styles.fieldContainer}>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  mode="flat"
                  value={values.email}
                  onChangeText={handleChange('email')}
                  onBlur={() => setFieldTouched('email')}
                  autoCapitalize="none"
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  returnKeyType="next"
                  underlineColor="#555555"
                  autoComplete="email"
                />
                {touched.email && errors.email && <Text style={styles.error}>{errors.email}</Text> }
              </View>

              <View style={styles.buttonContainer}>

                <Button mode="contained" disabled={!isValid} onPress={handleSubmit} >
                  Update Username
                </Button>

              </View>

            </Fragment>
          )}
        </Formik>

      </Surface>

    </ScreenContainerView>
  );
};

const styles = StyleSheet.create({
  fieldContainer: {
    width: '100%',
    marginVertical: 12,
  },
  error: {
    fontSize: 14,
    paddingHorizontal: 4,
    paddingTop: 4,
    color: 'red'
  },
  buttonContainer: {
    maxWidth: 600
  },
  surfaceContainer: {
    flex: 1,
    padding: Platform.OS === 'web' ? 30 : 10
  }
});
