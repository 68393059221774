import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {BottomTabParamList, RootTabScreenProps} from "../../types";
import EmployeeTakeTimeOffScreen from "../screens/app/employee/EmployeeTakeTimeOffScreen";
import {Pressable} from "react-native";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import EmployeeCalendarScreen from "../screens/app/employee/EmployeeCalendarScreen";
import * as React from "react";
import EmployeeReportsScreen from "../screens/app/employee/EmployeeReportsScreen";
import EmployeeBalancesScreen from "../screens/app/employee/EmployeeBalancesScreen";

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export const BottomTabNavigator = () => {

  return (
    <BottomTab.Navigator
      initialRouteName="EmployeeTakeTimeOffScreen"
      screenOptions={{ headerShown: false }}
    >
      <BottomTab.Screen
        name="EmployeeTakeTimeOffScreen"
        component={EmployeeTakeTimeOffScreen}
        options={({ navigation }: RootTabScreenProps<'EmployeeTakeTimeOffScreen'>) => ({
          title: 'Take Time Off',
          tabBarIcon: ({ color }) => <TabBarIcon name="alarm-plus" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <MaterialCommunityIcons
                name="information-outline"
                size={25}
                color={'red'}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name="EmployeeCalendarScreen"
        component={EmployeeCalendarScreen}
        options={{
          title: 'Calendar',
          tabBarIcon: ({ color }) => <TabBarIcon name="calendar-month" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="EmployeeReportsScreen"
        component={EmployeeReportsScreen}
        options={{
          title: 'Reports',
          tabBarIcon: ({ color }) => <TabBarIcon name="file-chart-outline" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="EmployeeBalancesScreen"
        component={EmployeeBalancesScreen}
        options={{
          title: 'Balances',
          tabBarIcon: ({ color }) => <TabBarIcon name="chart-bar" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

function TabBarIcon(props: {
  name: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
  color: string;
}) {
  return <MaterialCommunityIcons size={30} style={{ marginBottom: -3 }} {...props} />;
}
