import AsyncStorage from '@react-native-async-storage/async-storage';

const getItemAsync = async (key: string) => {
  return AsyncStorage.getItem(key);
}

const setItemAsync = async (key: string, value: string) => {
  return AsyncStorage.setItem(key, value);
}

const deleteItemAsync = async (key: string) => {
  return AsyncStorage.removeItem(key);
}

export default {
  getItemAsync,
  setItemAsync,
  deleteItemAsync
}
