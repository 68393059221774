import StoreService from './StoreService';

/*
 * Access and Refresh Tokens
 */
async function getAccessToken() {
  return await StoreService.getItemAsync('accessToken');

}

async function getRefreshToken() {
  return await StoreService.getItemAsync('refreshToken');

}

async function getTokens() {

  let accessToken = await StoreService.getItemAsync('accessToken');
  let refreshToken = await StoreService.getItemAsync('refreshToken');

  return {
    'accessToken': accessToken,
    'refreshToken': refreshToken
  };

}

async function setTokens(accessToken: any, refreshToken: any) {

  //console.log('KeyStoreService setTokens');
  //console.log('KeyStoreService setTokens, accessToken', accessToken);
  //console.log('KeyStoreService setTokens, refreshToken', refreshToken);

  await StoreService.setItemAsync('accessToken', accessToken.toString());
  await StoreService.setItemAsync('refreshToken', refreshToken.toString());

}

async function deleteTokens() {

  //console.log('KeyStoreService deleteTokens');

  await StoreService.deleteItemAsync('accessToken');
  await StoreService.deleteItemAsync('refreshToken');

}

/*
 * Push Notification Status
 */
const setPnStatus = async (pnStatus: any) => {
  await StoreService.setItemAsync('pnStatus', JSON.stringify(pnStatus));

};

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const getPnStatus = async () => {

  // delete the pnStatus from Secure Storage in order to test the refresh on the simulator
  //await SecureStore.deleteItemAsync('pnStatus');


  let pnStatus = {
    granted: false,
    token: "",
    device_id: "",
  };

  let res = await StoreService.getItemAsync('pnStatus');

  // make sure that we get back a string
  if (res && (typeof res === 'string')) {

    // convert the string (expected json) to an object
    let keyPnStatus = JSON.parse(res);

    // get the vars from the object
    let { granted = false, token = "", device_id = "" } = keyPnStatus;

    pnStatus.granted = granted;
    pnStatus.token = token;
    pnStatus.device_id = device_id;

  }

  if (pnStatus.device_id === "") {
    pnStatus.device_id = uuidv4();
    await setPnStatus(pnStatus);

  }

  return pnStatus;

};

/*
 * Slideshow Status
 */
const setShowSlideshow = async (show: boolean) => {

  let keySlideshow = {
    show
  };

  await StoreService.setItemAsync('showSlideshow', JSON.stringify(keySlideshow));

};

const getShowSlideshow = async () => {

  let res = await StoreService.getItemAsync('showSlideshow');

  console.log('res', res);

  // make sure that we get back a string
  if (res && (typeof res === 'string')) {

    // convert the string (expected json) to an object
    let keySlideshow = JSON.parse(res);

    // get the vars from the object
    let {show = true} = keySlideshow;

    return show;

  }

  return true;

};

export default {
  getAccessToken,
  getRefreshToken,
  getTokens,
  setTokens,
  deleteTokens,
  setPnStatus,
  getPnStatus,
  uuidv4,
  setShowSlideshow,
  getShowSlideshow
}
