import React from 'react';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {AppSidebar} from './AppSidebar';
import {AppStackNavigator} from "./AppStackNavigator";

const Drawer = createDrawerNavigator();

export const AppDrawerNavigator = () => {

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
      }}
      drawerContent={ props => <AppSidebar {...props} /> }
      defaultStatus="closed"
    >
      <Drawer.Screen name="AppStackNavigator" component={AppStackNavigator}/>
    </Drawer.Navigator>
  );

};

