import React, {memo, Fragment, useEffect, useState} from 'react';
import {TouchableOpacity, StyleSheet, View, Animated, Image} from 'react-native';
import Background from '../../components/Background';
import Button from '../../components/Button';
import {Text, useTheme, TextInput, Button as PaperButton, Title, TouchableRipple, Switch} from "react-native-paper";
import ApiService from '../../services/ApiService';
import TocApiService from "../../services/TimeOffCloudApiService";
import * as yup from 'yup';
import {Formik, Field, useFormikContext} from 'formik';
//import LinkingService from "../../services/LinkingService";
import ManifestService from "../../services/ManifestService";
//import {BioAuth} from "../../components/BioAuth";
import {useAuthStore} from '../../store/auth/authStore';
import {RootStackScreenProps} from "../../../types";
import AuthService from "../../services/AuthService";
import KeyStoreService from "../../services/KeyStoreService";
import Toast from "react-native-toast-message";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import LogoAndWordmark from "../../components/LogoAndWordmark";
import * as Animatable from "react-native-animatable";
import {useDataStore} from "../../store/data/dataStore";
import * as AppleAuthentication from 'expo-apple-authentication';
import ButtonGoogleSignin from "../../components/ButtonGoogleSignin";
import {PreferencesContext} from "../../context/preferencesContext";
import {Auth, Hub} from "aws-amplify";

const LoginScreen = ({ navigation }: RootStackScreenProps<'LoginScreen'>) => {

  const theme = useTheme();

  const { theme: selectedTheme, toggleTheme } = React.useContext( PreferencesContext );

  const {resetToInitialState, isLoading, setLoading, signIn, setUser, signOutAndShowSlideshow} = useAuthStore();
  const {setUserProfile, setWebContainerUrl} = useDataStore();

  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    console.log('LoginScreen useEffect, loaded');
    return () => {
      console.log('LoginScreen useEffect, unloaded');
    }
  }, []);

  useEffect(() => {

    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log('LoginScreen.useEffect[], signIn', data);

          Auth.currentSession().then((cognitoUserSession) => {

            console.log('LoginScreen.useEffect[].currentSession, session', cognitoUserSession);

            Auth.currentAuthenticatedUser()
              .then(currentUser => {
                console.log('LoginScreen.useEffect[].Hub.listen currentAuthenticatedUser', currentUser);

                setUser(cognitoUserSession);

                setUserProfile({
                  login_id: 1,
                  first_name: 'Jim',
                  last_name: 'Czekner',
                  role: 'EMPLOYEE',
                  active: true,
                  customer_email: 'jimczekner@icloud.com',
                  customer_id: '1',
                  isEmployee: true,
                  isManager: true,
                  isAdmin: true,
                  isAccountOwner: true
                })

                signIn();

              })
              .catch(() => console.log("LoginScreen.useEffect[].Hub.listen Not signed in"));

            })
            .catch((e) => {
              console.log('LoginScreen.useEffect[].currentSession, catch', e);
            });

          break;

        case "signOut":
          console.log('LoginScreen.useEffect[], signOut');
          break;
        case "customOAuthState":
          console.log('LoginScreen.useEffect[], customOAuthState', data);
        default:
          console.log('Hub.listen', event);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => console.log('LoginScreen.useEffect[] currentAuthenticatedUser', currentUser))
      .catch(() => console.log("LoginScreen.useEffect[] Not signed in"));

    return unsubscribe;

  }, []);

  const _onLoginPressed = async (values: any /*, { setErrors }*/ ) => {

    // prevent the login button from being clicked 2x
    if (isLoading) { return; }

    let done = false;
    let { idp, email, password } = values;

    setLoading(true);

    await AuthService.login(idp, '', email, password);

    /*
    let session = null;

    await AuthService.login(idp, '', email, password)
      .then((cognitoUserSession) => {

        session = cognitoUserSession;

        console.log('session', session);

      })
      .catch((e) => {
        console.log(e);

        Toast.show({ text1: 'Invalid credentials.', text2: e, type: 'error' });

        alert('Error: ' + e);

      });

    if (session) {

      setUser(session);

      setUserProfile({
        login_id: 1,
        first_name: 'Jim',
        last_name: 'Czekner',
        role: 'EMPLOYEE',
        active: true,
        customer_email: 'jimczekner@icloud.com',
        customer_id: '1',
        isEmployee: true,
        isManager: true,
        isAdmin: true,
        isAccountOwner: true
      })

      try {
        let passwordlessUrl = await AuthService.getPasswordlessUrl('/admin/employee/employee_list');
        console.log('LoginScreen passwordlessUrl', passwordlessUrl);

        setWebContainerUrl(passwordlessUrl);
      }
      catch(e) {
        console.log('LoginScreen passwordless error', e);
      }

      signIn();

    }

     */

    setLoading(false);

  };

  const _onLogoutShowSlideshowPressed = () => {
    signOutAndShowSlideshow();
  }

  const _onResetPressed = () => {
    KeyStoreService.setShowSlideshow(true)
      .then(() => {
        resetToInitialState();
      });

  }

  const _onSelectTheme = async () => {
    console.log('EmployeeCalendarScreen::_onSelectTheme');
    toggleTheme();
  }

  /** Place this code below the <Logo />
   *
      {ready &&
      <BioAuth onAuthenticated={_onAuthenticated} />
      }
   *
   */

  return (
    <Background>

      <LogoAndWordmark dark={true} />

      <View style={{paddingTop: 20}} />

      <Formik
        initialValues={{  idp: '', idp_name: '', email: '', password: '' }}
        onSubmit={_onLoginPressed}
        validationSchema={yup.object().shape({
          idp: yup.string().min(3).required('IDP Lookup is required'),
          idp_name: yup.string(),
          email: yup.string()
            .email('Please enter a valid email address.')
            .required('Email address is required.'),
          password: yup.string().min(6).required('Password is required.')
        })}
        validateOnChange={true}
      >

        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
          <Fragment>

            <View style={styles.fieldContainer}>

              { /* This is the first row for the textbox and button */ }
              <View style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  mode="flat"
                  value={values.email}
                  onFocus={() => {setFocus(true)}}
                  onBlur={() => { setFieldTouched('email'); setFocus(false); }}
                  onChange={() => {
                    // if the email address is modified, then the idp lookup must be done again
                    setFieldValue('idp', '');
                  }}
                  onChangeText={handleChange('email')}
                  autoCapitalize="none"
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  returnKeyType="next"
                  underlineColor="#555555"
                  autoComplete="email"
                  style={{
                    width: (values.idp == AuthService.IDP_UNDEFINED ? '85%' : '100%'),
                    borderTopRightRadius: 0
                  }}
                />

                {values.idp == AuthService.IDP_UNDEFINED &&
                <View
                    style={{
                      justifyContent: 'center',
                      width: '15%',
                      backgroundColor: theme.colors.primary,
                      borderTopRightRadius: 3
                    }}
                >
                    <TouchableOpacity onPress={() => {

                      if (!errors.email) {
                        AuthService.idpLookup(values.email)
                          .then((result) => {
                            console.log('idpLookup result', result);
                            let {idp = '', idp_name = ''} = result;
                            console.log('idp', idp);
                            console.log('idp_name', idp_name);

                            setFieldValue('idp', idp);
                            setFieldValue('idp_name', idp_name);
                          });

                      } else {
                        Toast.show({text1: 'Please enter your email address.', type: 'error'});
                      }

                    }}>

                      { /* Add an Activity Indicator here when the IDP lookup is in progress */ }

                        <MaterialCommunityIcons name="chevron-right" size={50} style={{
                          justifyContent: 'center',
                          alignSelf: 'center',
                          color: 'white'
                        }}/>
                    </TouchableOpacity>
                </View>
                }
              </View>

              { /* This is the second row, just for the underline */ }
              {values.idp == AuthService.IDP_UNDEFINED &&
              <View style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
                  <View style={{width: "85%"}}/>
                  <View style={{
                    width: '15%',
                    height: (hasFocus ? 0.5 : 0),
                    justifyContent: 'center',
                    backgroundColor: theme.colors.primary
                  }}/>
              </View>
              }
              {touched.email && errors.email && <Text style={styles.error}>{errors.email}</Text> }

              { /* Checking touched.email here causes the errors not to be displayed until there is a second field on the form */ }

            </View>

            {values.idp == AuthService.IDP_USERPASS &&
            <>
              { /* fadeIn, flipInY, lightSpeedIn, zoomInLeft, zoomIn, bounceIn */ }
              <Animatable.View animation={'flipInY'} style={styles.fieldContainer}>
                <TextInput
                  label="Password"
                  placeholder="Password"
                  mode="flat"
                  value={values.password}
                  onChangeText={handleChange('password')}
                  onBlur={() => setFieldTouched('password')}
                  secureTextEntry={true}
                  returnKeyType="done"
                  underlineColor="#555555"
                  autoComplete="password"
                />
                {touched.password && errors.password && <Text style={styles.error}>{errors.password}</Text>}

                <TouchableOpacity
                  style={styles.forgotPassword}
                  onPress={() => navigation.navigate('ForgotPasswordScreen')}
                >
                  <Text style={{ ...styles.label, color: theme.colors.primary }}>Forgot Password?</Text>
                </TouchableOpacity>

                <Button mode="contained" onPress={handleSubmit} loading={isLoading} >
                  Login
                </Button>
              </Animatable.View>
            </>
            }

            {(values.idp == AuthService.IDP_UNDEFINED || values.idp == AuthService.IDP_APPLE || values.idp == AuthService.IDP_GOOGLE) &&
            <>
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <View style={{width: "40%", height: '50%', borderBottomWidth: 1}} />
                <View style={{width: '20%',  }} >
                  <Text style={{justifyContent: 'center', alignSelf: 'center'}}>
                    OR
                  </Text>
                </View>
                <View style={{width: "40%", height: '50%', borderBottomWidth: 1}} />
              </View>

              <View style={styles.fieldContainer}>

                {(values.idp == AuthService.IDP_UNDEFINED || values.idp == AuthService.IDP_APPLE) &&
                <AppleAuthentication.AppleAuthenticationButton
                    buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                    buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
                    cornerRadius={5}
                    style={{ width: '100%', maxWidth: 320, height: 44, marginVertical: 10 }}
                    onPress={async () => {
                      //alert('Apple Login Pressed')
                      await _onLoginPressed({idp: AuthService.IDP_APPLE, idp_name: '', email: '', password: ''});
                    }}
                />
                }

                {(values.idp == AuthService.IDP_UNDEFINED || values.idp == AuthService.IDP_GOOGLE) &&
                <ButtonGoogleSignin
                    onPress={async () => {
                      //alert('Google Login Pressed')
                      /*
                      setFieldValue('idp', AuthService.IDP_GOOGLE);
                      setFieldValue('idp_name', '');
                      setFieldValue('email', '');
                      setFieldValue('password', '');
                       */
                      await _onLoginPressed({idp: AuthService.IDP_GOOGLE, idp_name: '', email: '', password: ''});
                    }}
                >
                </ButtonGoogleSignin>
                }

              </View>
            </>
            }

            <View style={{ paddingBottom: 60}}></View>

            <TouchableRipple onPress={_onSelectTheme} >
              <View style={styles.preference}>
                <Text style={styles.preferenceText }>Dark Theme</Text>
                <View pointerEvents="none">
                  <Switch
                    ios_backgroundColor="red"
                    trackColor={{true: 'green', false: 'red'}}
                    value={(selectedTheme == 'dark' ? true : false)}
                  />
                </View>
              </View>
            </TouchableRipple>

            <TouchableOpacity onPress={_onResetPressed} style={{alignSelf: "center", paddingTop: 20, paddingBottom: 20}}>
              <Text>Reset</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={_onLogoutShowSlideshowPressed} style={{alignSelf: "center", paddingTop: 20, paddingBottom: 20}}>
              <Text>Logout and Show Slideshow</Text>
            </TouchableOpacity>

            <Text style={{color: theme.colors.tocBlue, alignSelf: "center", paddingTop: 20, paddingBottom: 50}}>
              Version {ManifestService.getExtra('subver')}-{ManifestService.getExtra('env_name').slice(0, 1)}
            </Text>

          </Fragment>
        )}
      </Formik>

    </Background>
  );

}

const styles = StyleSheet.create({
  fieldContainer: {
    width: '100%',
    marginVertical: 6,
    paddingTop: 10
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 12,
    marginBottom: 4,
  },
  rowEnd: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 12,
  },
  label: {
  },
  link: {
    fontWeight: 'bold',
  },
  error: {
    fontSize: 14,
    paddingHorizontal: 4,
    paddingTop: 4,
    color: 'red'
  },


  preference: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  preferenceText: {
    paddingRight: 15
  }
});

export default memo(LoginScreen);
