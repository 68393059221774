import React, { memo } from 'react';
import { Image, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';

// @ts-ignore
import logo from "../../assets/images/icon.png";

const Logo = () => {

  const theme = useTheme();

  return (
    <Image source={logo} style={styles.image}/>
  );

};

const styles = StyleSheet.create({
  image: {
    width: 128,
    height: 128,
    marginTop: 50,
    marginBottom: 12,
    borderRadius: 10
  },
});

export default memo(Logo);
