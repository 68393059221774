import React, {useEffect} from 'react';
import {FlatList, View, StyleSheet, Platform} from 'react-native';
import {Surface, Text, useTheme} from 'react-native-paper';
import ScreenTitle from "../../../components/ScreenTitle";
import LinkingService from "../../../services/LinkingService";
import {extractNameFromGithubUrl, capitalizeFirstLetter} from "../../../services/UtilsService";
import ScreenContainerView from "../../../components/ScreenContainerView";
import {useDataStore} from "../../../store/data/dataStore";
import ManifestService from "../../../services/ManifestService";

import Data from '../../../data/licenseData';
import {RootStackScreenProps} from "../../../../types";
import {useIsFocused} from "@react-navigation/native";

const logo = require('../../../../assets/images/icon.png');
const metasection = require('../../../../assets/images/metasection.png');

/*
 https://blog.expo.io/licenses-the-best-part-of-your-app-29e7285b544f
 https://www.npmjs.com/package/npm-license-crawler
 */

export default function Licensing({ navigation }: RootStackScreenProps<'Licensing'>) {

  const theme = useTheme();

  const {setTrackingMenuId} = useDataStore();

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('About[Loading]');

    if (isFocused) {
      console.log('About[Focused]');
      setTrackingMenuId('about');
    }

    return () => {
      console.log('About[Unloading]');
    }

  }, [isFocused]);

  const licenses = Object.keys(Data).map(key => {
    let { licenses, ...license } = Data[key];
    let [name, version] = key.split('@');

    const reg = /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i;
    let username =
      extractNameFromGithubUrl(license.repository) ||
      extractNameFromGithubUrl(license.licenseUrl);

    let userUrl;
    let image;
    if (username) {
      username = capitalizeFirstLetter(username)
      image = `http://github.com/${username}.png`;
      userUrl = `http://github.com/${username}`;
    }

    return {
      key,
      name,
      image,
      userUrl,
      username,
      licenses: licenses.slice(0, 405),
      version,
      repositoryUrl: license.repository,
      ...license,
    };
  });

  const renderItem = ({ item }) => (
    <View style={{paddingBottom: 20}}>
      <Text>{(item.name.toLowerCase() !== item.username.toLowerCase() ? `${item.name} by ${item.username}` : item.name)}</Text>
      <Text style={styles.text}>{item.licenses}</Text>
      <Text style={styles.text}>{item.version}</Text>
      {item.repositoryUrl &&
      <Text style={{ color: theme.colors.tocBlue, paddingTop: 3, fontSize: 16 }} onPress={() => LinkingService.openURL(item.repositoryUrl)}>
        {item.repositoryUrl}
      </Text>
      }
    </View>
  );

  const appNameDeveloper = `${theme.appName} Developed`
  const metaSectionItem = { item: { name: appNameDeveloper, username: "Meta Section, LLC", image: metasection, licenses: "Copyright 2022", version: ManifestService.getExtra('subver'), repositoryUrl: 'https://www.metasection.com' }};

  return (
    <ScreenContainerView scrollable={false}>

      <ScreenTitle
        icon_name="bookmark"
        icon_color={'black'}
        title_text="Licensing"
        title_color="black"
        help_text="Technology Licensing and Copyrights"
        help_text_line2=""
        help_color={'gray'}
      >
        <View />
      </ScreenTitle>

        <FlatList
          ListHeaderComponent={
            <View>
              {renderItem(metaSectionItem)}
            </View>
          }
          style={{ flex: 1, }}
          keyExtractor={({ key }) => key}
          data={licenses}
          renderItem={renderItem}
          ListFooterComponent={
            <View />
          }
        />

    </ScreenContainerView>
  );
};

const styles = StyleSheet.create({
  text: {
    marginTop: 3,
  },

  surfaceContainer: {
    flex: 1,
    padding: Platform.OS === 'web' ? 30 : 10
  }

});
