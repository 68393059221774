import React from 'react';
import {Image, Platform, StyleSheet, useWindowDimensions, View} from 'react-native';
import {Appbar, useTheme, Title} from "react-native-paper";
import {getHeaderTitle} from "@react-navigation/elements";
import {DrawerNavigationProp} from "@react-navigation/drawer";
import {useAuthStore} from "../store/auth/authStore";
import {StackHeaderProps} from "@react-navigation/stack";

const logo = require('../../assets/images/icon.png');

type Props = StackHeaderProps;

const Header = (props: Props) => {

  const renderWebTitle = () => {
    return getHeaderTitle(props.options, props.route.name);
  }

  const renderMobileTitle = () => {

    return (
      <View style={{ flexDirection: "row" }}>
        <Image style={styles.header_image} source={logo} />
        <Title style={styles.header_text}>
          {getHeaderTitle(props.options, props.route.name)}
        </Title>
      </View>
    );

  }

  const title = (Platform.OS === 'web' ? renderWebTitle() : renderMobileTitle());

  const {width, height} = useWindowDimensions();
  const { signOut } = useAuthStore();

  const theme = useTheme();

  const displayMenuIcon = (navigation) => {

    const platformDrawerType = (Platform.OS === 'web' && width >= 1024 ? 'permanent' : 'front');

    if (platformDrawerType === 'permanent') {
      return;
    }

    //console.log('displayMenuIcon');

    return (<Appbar.Action
      icon="menu"
      onPress={() => {
        ((navigation as any) as DrawerNavigationProp<{}>).openDrawer();
      }}
      color={theme.colors.text}
      size={32}
    />);

  };

  // Need to use the Appbar instead of Appbar.Header on Android to
  // solve an issue with the StatusBar spacing
  if (Platform.OS === 'android') {

    return (
      <Appbar theme={{ colors: { primary: theme.colors.surface } } }>
        {props.back ? (
          <Appbar.BackAction
            onPress={props.navigation.goBack}
            color={theme.colors.text}
          />
        ) : (
          displayMenuIcon(props.navigation)
        )}
        <Appbar.Content
          title={ title }
          titleStyle={{
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.colors.text,
          }}
        />
        <Appbar.Action icon="location-exit" onPress={signOut} />
      </Appbar>
    );

  }

  return (
    <Appbar.Header theme={{ colors: { primary: theme.colors.surface } } }>
      {props.back ? (
        <Appbar.BackAction
          onPress={props.navigation.goBack}
          color={theme.colors.text}
        />
      ) : (
        displayMenuIcon(props.navigation)
      )}
      <Appbar.Content
        title={ title }
        titleStyle={{
          fontSize: 18,
          fontWeight: 'bold',
          color: theme.colors.text,
        }}
      />
      <Appbar.Action icon="location-exit" onPress={signOut} />
    </Appbar.Header>
  );

}


const styles = StyleSheet.create({
  header_image: {
    width: 26,
    height: 26,
    resizeMode: "cover",
    alignSelf: "center"
  },
  header_text: {
    paddingLeft: Platform.OS === 'ios' ? 8 : 10,
    fontSize: 20,
    fontWeight: 'bold'
  },
});

export default Header;
