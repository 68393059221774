export const initialState = {
  isSubmitting: false,
  userProfile: {
    login_id: '',
    first_name: '',
    last_name: '',
    role: '',
    active: '',
    customer_email: '',
    customer_id: '',
    isEmployee: false,
    isManager: false,
    isAdmin: false,
    isAccountOwner: false
  },
  upgrade: {
    build_version: '',
    description: '',
    description_all: '',
    upgrade_command: '',
    upgrade_type: ''
  },
  focused: '',
  delayedNav: '',
  webContainerUrl: '',
  webContainerLoggedIn: false,
  trackingUri: '',
  trackingMenuId: '',
  targetUri: ''
};

export const reducer = (prevState: any, action: any) => {

  let { payload: { test_available = false } = false } = action;

  switch (action.type) {

    case 'USER_PROFILE':

      return {
        ...prevState,
        userProfile: (action.payload ? action.payload : initialState.userProfile),
        isSubmitting: false,
      };

    case 'SET_UPGRADE':

      return {
        ...prevState,
        upgrade:  (action.payload ? action.payload : initialState.upgrade),
        isSubmitting: false,
      };

    case 'SET_FOCUSED':

      return {
        ...prevState,
        focused:  (action.payload ? action.payload : initialState.focused)
      };

    case 'SET_DELAYED_NAV':

      return {
        ...prevState,
        delayedNav:  (action.payload ? action.payload : initialState.delayedNav)
      };

    case 'SET_WEB_CONTAINER_URL':

      return {
        ...prevState,
        webContainerUrl:  (action.payload ? action.payload : initialState.webContainerUrl)
      };

    case 'SET_WEB_CONTAINER_LOGGED_IN':

      return {
        ...prevState,
        webContainerLoggedIn:  (action.payload ? action.payload : initialState.webContainerLoggedIn)
      };

    case 'SET_TRACKING_URI':

      return {
        ...prevState,
        trackingUri:  (action.payload ? action.payload : initialState.trackingUri)
      };

    case 'SET_TRACKING_MENU_ID':

      return {
        ...prevState,
        trackingMenuId:  (action.payload ? action.payload : initialState.trackingMenuId)
      };

    case 'SET_TARGET_URI':

      return {
        ...prevState,
        targetUri:  (action.payload ? action.payload : initialState.targetUri)
      };

    case 'LOGOUT':

      return {
        ...prevState,
        isSubmitting: false,
        userProfile: initialState.userProfile,
        upgrade: initialState.upgrade,
        focused: initialState.focused,
        delayedNav: initialState.delayedNav
      };

  }
};
