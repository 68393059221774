import React, {useCallback, useEffect, useState} from 'react';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Appearance, Platform, View} from 'react-native';
import { Navigation } from './src/navigation/Navigation';
import {AuthProvider} from "./src/store/auth/authStore";
import {DataProvider} from "./src/store/data/dataStore";
import {Provider as PaperProvider} from 'react-native-paper';
import {darkTheme, lightTheme} from "./theme";
import {PreferencesContext} from "./src/context/preferencesContext";
import Toast from 'react-native-toast-message';
import * as Font from "expo-font";
import {FontAwesome} from "@expo/vector-icons";

import Amplify from '@aws-amplify/core';
import config from './aws-exports-manual';

SplashScreen.preventAutoHideAsync();

Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
});

export default function App() {

  const [appIsReady, setAppIsReady] = useState(false);
  const colorScheme = Appearance.getColorScheme();

  useEffect(() => {

    //console.log('App, useEffect []');

    async function prepare() {

      try {

        AsyncStorage.getItem('appTheme').then(
          r => {
            if (r === 'dark' || r === 'light') {
              //console.log('Main get appTheme', r);
              setTheme(r);
            }
          }
        );

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        });

        // Artificially delay for two seconds to simulate a slow loading
        // experience. Please remove this if you copy and paste the code!
        //await new Promise(resolve => setTimeout(resolve, 2000));

      } catch (e) {
        console.warn(e);

      } finally {
        // Tell the application to render
        setAppIsReady(true);

      }

    }

    prepare();

  }, []);


  const [theme, setTheme] = React.useState<'light' | 'dark'>(
    colorScheme === 'dark' ? 'dark' : 'light'
  );

  const preferencesContext = React.useMemo(
    () => ({
      toggleTheme: () => {
        let selected = (theme === 'light' ? 'dark' : 'light');
        // @ts-ignore
        setTheme(selected);
        AsyncStorage.setItem('appTheme', selected);
        //console.log('Main set appTheme', selected);
      },
      theme,
    }),
    [theme]
  );

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {

      //alert('config: ' + JSON.stringify(config));

      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);


  if (!appIsReady) {
    return null;
  }

  return (
    <SafeAreaProvider>
      <PreferencesContext.Provider value={preferencesContext}>
        <AuthProvider>
          <DataProvider>
            <PaperProvider theme={theme === 'light' ? lightTheme : darkTheme}>
              <Navigation />
              <Toast />
            </PaperProvider>
          </DataProvider>
        </AuthProvider>
      </PreferencesContext.Provider>
      <StatusBar style={Platform.OS === 'ios' ? (theme === 'light' ? 'auto' : 'light') : 'auto'} />
      <View onLayout={onLayoutRootView} />
    </SafeAreaProvider>
  );

}
