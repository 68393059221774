export default {
    "expo@46.0.0": {
        "licenses": "MIT",
        "repository": "https://github.com/expo/expo",
        "licenseUrl": "https://github.com/expo/expo",
        "parents": "timeoffcloud"
    },
    "aws-amplify@4.3.30": {
        "licenses": "Apache 2.0",
        "repository": "https://github.com/aws-amplify/amplify-js",
        "licenseUrl": "https://github.com/aws-amplify/amplify-js/blob/main/LICENSE",
        "parents": "timeoffcloud"
    },
    "formik@2.2.9": {
        "licenses": "Apache 2.0",
        "repository": "https://github.com/formium/formik",
        "licenseUrl": "https://github.com/formium/formik/raw/master/LICENSE",
        "parents": "timeoffcloud"
    },
    "lottie-react-native@5.1.3": {
        "licenses": "Apache 2.0",
        "repository": "https://github.com/lottie-react-native/lottie-react-native",
        "licenseUrl": "https://github.com/lottie-react-native/lottie-react-native/blob/master/LICENSE",
        "parents": "timeoffcloud"
    },
    "react@18.0.0": {
        "licenses": "MIT",
        "repository": "https://github.com/facebook/react",
        "licenseUrl": "https://github.com/facebook/react/raw/master/LICENSE",
        "parents": "timeoffcloud"
    },
    "react-native@0.69.5": {
        "licenses": "MIT",
        "repository": "https://github.com/facebook/react-native",
        "licenseUrl": "https://github.com/facebook/react-native/raw/master/LICENSE",
        "parents": "timeoffcloud"
    },
    "react-native-paper@5.0.0-rc.5": {
        "licenses": "MIT",
        "repository": "https://github.com/callstack/react-native-paper",
        "licenseUrl": "https://github.com/callstack/react-native-paper/raw/master/LICENSE.md",
        "parents": "timeoffcloud"
    },
    "yup@0.32.11": {
        "licenses": "MIT",
        "repository": "https://github.com/jquense/yup",
        "licenseUrl": "https://github.com/jquense/yup/raw/master/LICENSE.md",
        "parents": "timeoffcloud"
    }
};
