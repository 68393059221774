import React, {useReducer, useContext, ReactNode} from 'react';
import {initialState, reducer} from './authStoreReducer';
import {AuthContextType} from "./authStoreTypes";
import TimeOffCloudApiService from "../../services/TimeOffCloudApiService";

const AuthContext = React.createContext<AuthContextType>({
  resetToInitialState: () => {},
  setLoading: () => {},
  showSlideshow: () => {},
  showLogin: () => {},
  restoreToken: () => {},
  signIn: () => {},
  signOut: () => {},
  signOutAndShowSlideshow: () => {},
  setUser: (data) => {},
  ...initialState
});

export const useAuthStore = () => {
  return useContext(AuthContext);
};

type Props = {
  children: ReactNode
};

export const AuthProvider = ({ children }: Props) => {

  const [contextValue, dispatch] = useReducer(reducer, initialState);

  // reset back to initialState
  contextValue.resetToInitialState = () => {
    dispatch({ type: 'RESET_TO_INITIALSTATE' });

  };

  // set the loading state
  contextValue.setLoading = (data: any) => {
    dispatch({ type: 'SET_LOADING', payload: data });

  };

  // show the slideshow
  contextValue.showSlideshow = () => {
    dispatch({ type: 'SHOW_SLIDESHOW' });

  };

  // show the login screens
  contextValue.showLogin = () => {
    dispatch({ type: 'SHOW_LOGIN' });

  };

  // restore the token
  contextValue.restoreToken = async () => {
    dispatch({ type: 'RESTORE_TOKEN' });

  };

  // sign in
  contextValue.signIn = async () => {
    dispatch({ type: 'SIGN_IN' });

  };

  // sign out
  contextValue.signOut = async () => {

    try {
      await TimeOffCloudApiService.logout();

    } catch (err) {
      //console.log('authStore error', err.message);
    }

    dispatch({ type: 'SIGN_OUT' });

  }

  // sign out
  contextValue.signOutAndShowSlideshow = async () => {

    try {
      await TimeOffCloudApiService.logout();

    } catch (err) {
      //console.log('authStore error', err.message);
    }

    dispatch({ type: 'SIGN_OUT_AND_SHOW_SLIDESHOW' });

  }

  contextValue.setUser = async (data: any) => {
    dispatch({ type: 'SET_USER', payload: data });
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );

};
