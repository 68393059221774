import {StyleSheet, View} from 'react-native';
import { RootStackScreenProps } from '../../../types';


export default function Home({ navigation }: RootStackScreenProps<'Home'>) {

  return (
    <View style={styles.container}>
    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'red'
  }
});
