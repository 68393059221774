import React from 'react';
import {View, ScrollView, Platform} from 'react-native';
import {useTheme} from "react-native-paper";
import {StatusBar} from "expo-status-bar";

type Props = {
  scrollable: boolean;
  fullscreen?: boolean;
  children: React.ReactNode;
};

const ScreenContainerView = ({ scrollable, fullscreen = false, children }: Props) => {

  const theme = useTheme();

  let containerPadding = (Platform.OS === 'web' ? 30 : 20);

  if (fullscreen) {
    containerPadding = 0;
  }

  if (scrollable) {
    return (
      <ScrollView style={{ flex: 1, padding: containerPadding }}>
        {children}
        <View style={{ paddingBottom: 100 }} />
        <StatusBar style={(Platform.OS=="ios" ? "dark" : "light")} backgroundColor="#000000" translucent={false} />
      </ScrollView>
    );
  }

  return (
    <View style={{ flex: 1, padding: containerPadding }}>
      {children}
    </View>
  );

};

export default ScreenContainerView;
