import KeyStoreService from './KeyStoreService';
import ManifestService from "./ManifestService";

const defaultApiResponse = {
  _connected: false,
  _authorized: false,
  _statusCode: 0,
  _errorMessage: false,
  _errorMessages: false,
  status: false,
  action_code: 0,
  message: '',
  time_stamp: '',
  payload: {}
};

const HTTP_STATUS_CODES = {
  'CODE_200' : 'OK',
  'CODE_201' : 'Created',
  'CODE_202' : 'Accepted',
  'CODE_203' : 'Non-Authoritative Information',
  'CODE_204' : 'No Content',
  'CODE_205' : 'Reset Content',
  'CODE_206' : 'Partial Content',
  'CODE_300' : 'Multiple Choices',
  'CODE_301' : 'Moved Permanently',
  'CODE_302' : 'Found',
  'CODE_303' : 'See Other',
  'CODE_304' : 'Not Modified',
  'CODE_305' : 'Use Proxy',
  'CODE_307' : 'Temporary Redirect',
  'CODE_400' : 'Bad Request',
  'CODE_401' : 'Unauthorized',
  'CODE_402' : 'Payment Required',
  'CODE_403' : 'Forbidden',
  'CODE_404' : 'Not Found',
  'CODE_405' : 'Method Not Allowed',
  'CODE_406' : 'Not Acceptable',
  'CODE_407' : 'Proxy Authentication Required',
  'CODE_408' : 'Request Timeout',
  'CODE_409' : 'Conflict',
  'CODE_410' : 'Gone',
  'CODE_411' : 'Length Required',
  'CODE_412' : 'Precondition Failed',
  'CODE_413' : 'Upload Too Large',
  'CODE_414' : 'Request-URI Too Long',
  'CODE_415' : 'Unsupported Media Type',
  'CODE_416' : 'Requested Range Not Satisfiable',
  'CODE_417' : 'Expectation Failed',
  'CODE_500' : 'Internal Server Error',
  'CODE_501' : 'Not Implemented',
  'CODE_502' : 'Bad Gateway',
  'CODE_503' : 'Service Unavailable',
  'CODE_504' : 'Gateway Timeout',
  'CODE_505' : 'HTTP Version Not Supported'
};

/*
'CODE_413' : 'Request Entity Too Large'
 */


const getResponseCodeDesc = (httpStatusCode) => {

  let result = 'Communications error';

  let responseCodeConstant = 'CODE_'.concat(httpStatusCode);

  if (HTTP_STATUS_CODES[responseCodeConstant]) {
    result = HTTP_STATUS_CODES[responseCodeConstant];

  }

  return result;

};

const getTokensFromAuthPayload = (data) => {

  if (!data || typeof data === 'undefined') {
    return { access_token: false, refresh_token: false };
  }

  let {
    login_id: {
      payload: {
        tokens: {
          login_id = false
        } = false
      } = false
    } = data,
    access_token: {
      payload: {
        tokens: {
          access_token = false
        } = false
      } = false
    } = data,
    refresh_token: {
      payload: {
        tokens: {
          refresh_token = false
        } = false
      } = false
    } = data,
    role: {
      payload: {
        tokens: {
          role = false
        } = false
      } = false
    } = data,
  } = data;

  return { login_id, access_token, refresh_token, role };

}

const getMessageInfoFromPayload = (message, _errorMessage, _errorMessages) => {

  // display a toast message
  let responseMessage = ((message && message.length > 3) ? message : 'Unable to complete operation.');
  _errorMessage = ((_errorMessage && _errorMessage.length > 3) ? _errorMessage : responseMessage);

  // set the field level errors
  _errorMessages = (!_errorMessages ? {} : _errorMessages);

  return {
    msg: _errorMessage,
    msgs: _errorMessages
  };

}


const refreshTokens = async () => {

  const refreshToken = await KeyStoreService.getRefreshToken();

  let data = null;

  /*
   * Changes from v1:
   *
   * Changed url from /oauth2/token.json to /auth/refresh.json
   * Removed grant_type and client_secret
   */

  await fetch(`${ManifestService.getExtra('root')}/auth/refresh.json`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: `${ManifestService.getExtra('client_id')}`,
      refresh_token: refreshToken,
    }),
  })
    .then(res => res.json())
    .then((res) => { data = res; })
    .catch(err => {
      data = null;
      //console.log('ApiService refreshTokens(), err:', err);
    });

  if (!data) {
    return false;
  }

  let { access_token = false, refresh_token = false } = getTokensFromAuthPayload(data);

  if (access_token && refresh_token) {
    await KeyStoreService.setTokens(access_token, refresh_token);
  }

  return access_token;

};

const handleNotAuthorizedAndTryAgain = async (url, options, enableAuth, response) => {

  if (response.ok) {
    return response;

  }
  else if (!enableAuth) {
    return response;

  }
  else {

    // if we got a 401
    if (response.status === 401) {

      //console.log('ApiService handleNotAuthorizedAndTryAgain(), 401 unauthorized received, fetching tokens');

      // call the API to exchange refresh token for new tokens
      let access_token = await refreshTokens();

      if (access_token) {

        //console.log('ApiService handleNotAuthorizedAndTryAgain(), access_token:', access_token);

        options.headers.Authorization = `Bearer ${access_token}`;

        let data = null;

        //console.log('ApiService handleNotAuthorizedAndTryAgain(), tokens received, retrying api call');

        // run the original API call again
        await fetch(url, options)
          .then((retryResponse) => {
            data = retryResponse;
          })
          .catch(err => {
            data = null;
            //console.log('ApiService handleNotAuthorizedAndTryAgain(), fetch retry catch:', err);
          });

        if (data) {
          return data;
        }

      }

    }
    else {
      //console.log('ApiService handleNotAuthorizedAndTryAgain(), http status code:', response.status);

    }

    //throw new Error(response.status);

    let error = new Error(response.status);
    error.response = response;
    throw error;

  }

};

const apiFetch = async (url, options, enableAuth) => {

  //console.log('ApiService apiFetch begin');

  let apiDefaultResponse = JSON.parse(JSON.stringify(defaultApiResponse));

  let data = null;

  // if auth is enabled then add the Authorization header
  if (enableAuth) {
    let token = await KeyStoreService.getAccessToken();

    //console.log('ApiService token', token);

    if (!token) {
      return apiDefaultResponse;
    }

    options.headers.Authorization = `Bearer ${token}`;
  }

  let statusCode = 0;

  //console.log('ApiService -------------------');

  await fetch(url, options)
    .then(response => handleNotAuthorizedAndTryAgain(url, options, enableAuth, response))
    .then(response => {

      //console.log('ApiService response.status', response.status);
      statusCode = response.status;
      return response;

    })
    .then(response => response.json())
    .then((response) => {

      //console.log(`ApiService apiFetch(), ${url}, response(obj):`, response);

      data = response;

      data._connected = true;
      data._authorized = (statusCode !== 401);
      data._statusCode = statusCode;

      let { payload = false } = data;
      let { validation_errors = false } = payload;
      let { errorMessage = false, errorMessages = false } = validation_errors;

      data._errorMessage = errorMessage;
      data._errorMessages = errorMessages;

      //console.log(`ApiService apiFetch(), ${url}, data(obj):`, data);

    })
    .catch(err => {

      data = null;

      //console.log('ApiService apiFetch(), err:', err);

      let { response: { status = false} = false } = err;

      if (status) {

        //console.log('ApiService apiFetch(), err.response.status:', status);

        data = apiDefaultResponse;
        data._connected = (status === 401);
        data._statusCode = status;

      }

    });

  if (!data) {
    //console.log('ApiService apiFetch(), setting default response');
    data = apiDefaultResponse;

  }

  // include an error message based on the http status code
  // if the http status code is available and the error message has not been set
  if (data._statusCode && !data._errorMessage) {

    //console.log('ApiService setting the response code', getResponseCodeDesc(data._statusCode));
    data._errorMessage = getResponseCodeDesc(data._statusCode);

  }

  return data;

};

export default {
  handleNotAuthorizedAndTryAgain,
  apiFetch,
  getTokensFromAuthPayload,
  getMessageInfoFromPayload
}
