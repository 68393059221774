import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Text, Title, useTheme} from 'react-native-paper';
import { RootStackScreenProps } from '../../../types';
import React, {useEffect, useRef} from "react";
import LottieView from "lottie-react-native";

export default function Slide2({ navigation }: RootStackScreenProps<'Slide2'>) {

  const theme = useTheme();

  const animation = useRef(null);

  useEffect(() => {
    console.log('Loading[Slide2]');
  }, []);

  const _onBackPressed = () => {
    navigation.goBack();
  }

  const _onForwardPressed = () => {
    navigation.navigate('Slide3');
  }

  return (
    <View style={[styles.container, {backgroundColor: theme.colors.tocBlue}]}>
      <Title style={{color: 'white'}}>Request Time Off</Title>

      <LottieView
        autoPlay
        ref={animation}
        style={{
          width: 300,
          height: 300,
          backgroundColor: theme.colors.tocBlue,
        }}
        // Find more Lottie files at https://lottiefiles.com/featured
        source={require('../../../assets/lottie/118000-email-open.json')}
      />

      <TouchableOpacity onPress={_onForwardPressed} style={{ paddingTop: 30 }}>
        <Text>Go Forward</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={_onBackPressed} style={{ paddingTop: 30 }}>
        <Text>Go Back</Text>
      </TouchableOpacity>


    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
