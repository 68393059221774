import React from 'react';

const hasAdminPanelAccess = (isAccountOwner:boolean, isAdmin:boolean, isManager:boolean) => {

  let result = false;

  if (isAccountOwner || isAdmin || isManager) {
    result = true;
  }

  return result;

};

export default {
  hasAdminPanelAccess,
};

