import React from 'react';
import {View} from 'react-native';
import {Text} from "react-native-paper";
import {MaterialCommunityIcons} from "@expo/vector-icons";

type Props = {
  label_color?: string;
  label_text: string;
  label_text_color?: string;
  label_font_weight?: string;
  dot_color?: string;
  border_color?: string;
};

const Badge = ({ label_color = '#000000', label_text,
                  label_text_color = '#FFFFFF', label_font_weight = 'bold',
                  dot_color = '',
                  border_color = ''}: Props) => {

  return (
    <View style={{
      borderRadius: 3,
      borderWidth: 1,
      padding: 5,
      marginTop: 3,
      marginRight: 6,
      marginBottom: 3,
      backgroundColor: label_color,
      borderColor: (border_color == '' ? label_color : border_color),
    }}>
        <Text style={{ fontWeight: label_font_weight, color: label_text_color }} >
          {(dot_color != '') &&
          <View style={{ paddingRight: 5}}>
              <MaterialCommunityIcons name="circle" size={12} style={{color: dot_color}}/>
          </View>
          }
          {label_text}
        </Text>
    </View>
  );

};

export default Badge;
