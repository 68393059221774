import React, { memo } from 'react';
import {StyleSheet, Text, View} from 'react-native';
import { useTheme } from 'react-native-paper';

type Props = {
  children: React.ReactNode;
};

const Footer = ({ children }: Props) => {

  const theme = useTheme();

  // @ts-ignore
  let color = theme.colors.primaryLight;

  return (
    <View style={ [styles.footerContent, { backgroundColor: theme.colors.surface } ]}>
      <Text style={ [ styles.footerText, { color } ]}>{children}</Text>
    </View>
  );

};

const styles = StyleSheet.create({
  footerContent: {

  },
  footerText: {
    fontSize: 10,
    fontWeight: 'bold',
    paddingVertical: 14,
    alignSelf: 'center'
  }
});

export default Footer;
