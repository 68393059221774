import React, {useState, useRef, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {WebView} from 'react-native-webview';

// @ts-ignore
import URL from 'url-parse';
import {useDataStore} from "../store/data/dataStore";

interface WebContainerProps {
  target: string;
  onWebNavigation: (page: string) => void;
}

export const WebContainer = ({ target, onWebNavigation }: WebContainerProps): JSX.Element => {

  const {targetUri} = useDataStore();

  const [messageRx, setMessageRx] = useState('Empty');
  const webViewRef = useRef(null);

  const [currentURI, setURI] = useState(target);

  const _onNavigationStateChange = (webViewNavigation) => {

    console.log('webViewNavigation', webViewNavigation);

    let { navigationType = ''} = webViewNavigation;

    if (navigationType == 'click') {
      // it appears that navigationType = 'click' is before the navigation

    }
    else {

      if (webViewNavigation.url === currentURI) {
        // reloading the same page
        console.log('loading the same page', currentURI);

      }
      else {
        // loaded a different page
        console.log('loaded a different page', currentURI);
        setURI(webViewNavigation.url);

      }

      const url = new URL(webViewNavigation.url);
      onWebNavigation(url.pathname);

    }

  }

  const _onMessage = (event) => {

    console.log('WebContainer Message Received from Webview', event.nativeEvent.data);

    setMessageRx('webview received ' + JSON.stringify(event.nativeEvent.data));

  }

  const _onLoad = () => {

    console.log('_onLoad');

    var unixtime = new Date().getTime();

    webViewRef.current.postMessage("rn:" + unixtime, '*');

    // tell the parent that the page has loaded
    //onWebNavigation('nav:' + unixtime);

  }

  const runFirst = `
  
      //
      // turn off the pinch/zoom
      //
      document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
      
      //
      // hide the navbar
      //
      document.querySelector(".navbar-header").style.display = "none";
            
      //window.alert('runFirst injected');
      
      // note: this is required, or you'll sometimes get silent failures
      true;       
    `;

  //      <Text>{ messageRx }</Text>

  return (
    <View style={{ flex: 1 }}>

      <WebView
        ref={webViewRef}
        source={{ uri: (targetUri != '' ? targetUri : target) }}
        onLoad={_onLoad}
        onMessage={_onMessage}
        onNavigationStateChange={_onNavigationStateChange}
        style={styles.webview}
        originWhitelist={['*']}
        injectedJavaScript={runFirst}
      />

    </View>
  );

};

const styles = StyleSheet.create({
  webview: {
    height: '100%',
    width: '100%'
  }
});
