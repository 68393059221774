import React, {memo, Fragment, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Logo from '../../components/Logo';
import Background from '../../components/Background';
import Button from '../../components/Button';
import {Text, useTheme, TextInput} from "react-native-paper";
import ApiService from "../../services/ApiService";
import TocApiService from "../../services/TimeOffCloudApiService";
import * as yup from 'yup';
import {Formik} from 'formik';
import {RootStackScreenProps} from "../../../types";
import Toast from 'react-native-toast-message';
import BackButton from "../../components/BackButton";

const ForgotPasswordScreen = ({ navigation }: RootStackScreenProps<'ForgotPasswordScreen'>) => {

  const theme = useTheme();

  const [isLoading, setLoading] = useState(false);

  const _onRequestPasswordResetPressed = async (values: any, { setErrors }) => {

    Toast.show({ text1: 'Please check your email.', text2: '(Swipe to dismiss message)', type: 'info', autoHide: false /*, action: toaster.hide*/ });

    let { email } = values;

    try {

      console.log('ForgotPasswordScreen _onRequestPasswordResetPressed');

      setLoading(true);

      const apiResponse = await TocApiService.forgotPassword(email);

      let { _connected = false, _authorized = false, message = '', _errorMessage = '', _errorMessages = false, status = false } = apiResponse;

      if (status) {

        console.log('ForgotPasswordScreen apiResponse', apiResponse);

        //toaster.show({ message: 'Please check your email.', type: 'success', action: toaster.hide});

      }
      else {

        // field level and toast messages
        let allMessages = ApiService.getMessageInfoFromPayload(message, _errorMessage, _errorMessages);
        setErrors(allMessages.msgs);
        //toaster.show({message: allMessages.msg, type: 'error', action: toaster.hide});

      }

    } catch (err) {
      //toaster.show({ message: 'Unable to request password reset. Please try again later.', type: 'error', action: toaster.hide});
    }

    setLoading(false);

  };

  return (
    <Background>
      <BackButton goBack={() => navigation.navigate('LoginScreen')} />

      <Logo />

      <Formik
        initialValues={{ email: '' }}
        onSubmit={_onRequestPasswordResetPressed}
        validationSchema={yup.object().shape({
          email: yup.string().email('Please enter a valid email address.').required('Email address is required.')
        })}
      >

        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <Fragment>

            <View style={styles.fieldContainer}>
              <TextInput
                label="Email"
                placeholder="Email"
                mode="flat"
                value={values.email}
                onChangeText={handleChange('email')}
                onBlur={() => setFieldTouched('email')}
                autoCapitalize="none"
                textContentType="emailAddress"
                keyboardType="email-address"
                returnKeyType="next"
                underlineColor="#555555"
                autoComplete="email"
              />
              {touched.email && errors.email && <Text style={styles.error}>{errors.email}</Text> }
            </View>

            <View style={styles.fieldContainer}>
              <Button mode="contained" onPress={handleSubmit} loading={isLoading} >
                Request Password Reset
              </Button>
            </View>

          </Fragment>
        )}
      </Formik>
    </Background>
  );

}

const styles = StyleSheet.create({
  fieldContainer: {
    width: '100%',
    marginVertical: 12,
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  label: {
  },
  link: {
    fontWeight: 'bold',
  },
  error: {
    fontSize: 14,
    paddingHorizontal: 4,
    paddingTop: 4,
    color: 'red'
  },
  back: {
    width: '100%',
    marginTop: 12,
  },
});

export default memo(ForgotPasswordScreen);
