import React, {Fragment, useEffect, useState} from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import {Surface, Text, TextInput, Title, useTheme,} from 'react-native-paper';
import ScreenTitle from "../../../components/ScreenTitle";
import Button from "../../../components/Button";
import {Formik} from "formik";
import * as yup from "yup";
import ApiService from "../../../services/ApiService";
import ScreenContainerView from "../../../components/ScreenContainerView";

import {useAuthStore} from "../../../store/auth/authStore";
import {useDataStore} from "../../../store/data/dataStore";
import {RootStackScreenProps} from "../../../../types";
import {useIsFocused} from "@react-navigation/native";
import Toast from "react-native-toast-message";
import AuthService from "../../../services/AuthService";

export default function ChangePassword({ navigation }: RootStackScreenProps<'ChangePassword'>) {

  const theme = useTheme();

  const {signOut} = useAuthStore();
  const {userProfile, setTrackingMenuId} = useDataStore();

  const [loading, setLoading] = useState(false);

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('ChangePassword[Loading]');
    if (isFocused) {
      console.log('ChangePassword[Focused]');
      setTrackingMenuId('profile');
    }

    return () => {
      console.log('ChangePassword[Unloading]');
    }

  }, [isFocused]);

  const passwordRegEx = /^(?=(.*[\d]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[!\-+@#$%_^&*()=~?]){1,})(?:[\da-zA-Z!\-+@#$%_^&*()=~?]){6,20}$/;

  const _onRequestChangePasswordPressed = async (values, { setErrors }) => {

    let { old_password, new_password } = values;

    try {

      //console.log('ChangePassword _onLoginPressed');

      setLoading(true);

      const apiResponse = await TcApiService.changePassword(old_password, new_password);

      let { _connected = false, _authorized = false, message = '', _errorMessage = '', _errorMessages = false, status = false } = apiResponse;

      if (status) {

        //console.log('ChangePassword apiResponse', apiResponse);
        Toast.show({ text1: 'Your password has been changed.', text2: '(Swipe to dismiss message)', type: 'success', autoHide: true });

        await AuthService.logout();
        signOut();

      }
      else {

        // field level and toast messages
        let allMessages = ApiService.getMessageInfoFromPayload(message, _errorMessage, _errorMessages);
        setErrors(allMessages.msgs);
        Toast.show({ text1: allMessages.msg, type: 'error', autoHide: true });

      }

    } catch (err) {
      Toast.show({ text1: 'Unable to request password update. Please try again later.', type: 'error', autoHide: true });
    }

    setLoading(false);

  };

  return (
    <ScreenContainerView scrollable={true}>

      <ScreenTitle
        icon_name="lock-outline"
        icon_color={'black'}
        title_text="Change Password"
        title_color="black"
        help_text="Please enter your current password, new password, and confirm."
        help_text_line2=""
        help_color={'gray'}
      >
        <View />
      </ScreenTitle>

      <Surface style={styles.surfaceContainer}>

        <Formik
          initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
          onSubmit={_onRequestChangePasswordPressed}
          validationSchema={yup.object().shape({
            old_password: yup.string().min(6).required('Password is required.').matches(passwordRegEx,
              "Password must be between 6 and 20 characters and \ncontain at least one lowercase letter, \none uppercase letter, \none number, \nand one symbol (! - + @ # $ % _ ^ & * = ~ ?)"
            ),
            new_password: yup.string().min(6).required('Password is required.').matches(passwordRegEx,
              "Password must be between 6 and 20 characters and \ncontain at least one lowercase letter, \none uppercase letter, \none number, \nand one symbol (! - + @ # $ % _ ^ & * = ~ ?)"
            ),
            confirm_password: yup.string().oneOf([yup.ref('new_password')], 'Password does not match').required('Required')
          })}
        >

          {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
            <Fragment>

              <Title>Current Password</Title>

              <View style={styles.fieldContainer}>
                <TextInput
                  label="Current Password"
                  placeholder="Current Password"
                  mode="flat"
                  value={values.password}
                  onChangeText={handleChange('old_password')}
                  onBlur={() => setFieldTouched('old_password')}
                  secureTextEntry={true}
                  returnKeyType="next"
                  underlineColor="#555555"
                />
                {touched.old_password && errors.old_password && <Text style={styles.error}>{errors.old_password}</Text>}
              </View>

              <Title>New Password</Title>

              <View style={styles.fieldContainer}>
                <TextInput
                  label="New Password"
                  placeholder="New Password"
                  mode="flat"
                  value={values.password}
                  onChangeText={handleChange('new_password')}
                  onBlur={() => setFieldTouched('new_password')}
                  secureTextEntry={true}
                  returnKeyType="next"
                  underlineColor="#555555"
                />
                {touched.new_password && errors.new_password && <Text style={styles.error}>{errors.new_password}</Text>}
              </View>

              <View style={styles.fieldContainer}>
                <TextInput
                  label="Re-Type New Password"
                  placeholder="Re-Type New Password"
                  mode="flat"
                  value={values.confirm_password}
                  onChangeText={handleChange('confirm_password')}
                  onBlur={() => setFieldTouched('confirm_password')}
                  secureTextEntry={true}
                  returnKeyType="done"
                  underlineColor="#555555"
                />
                {touched.confirm_password && errors.confirm_password &&
                <Text style={styles.error}>{errors.confirm_password}</Text>}
              </View>
              <View style={styles.buttonContainer}>

                <Button mode="contained" disabled={!isValid} onPress={handleSubmit} >
                  Update Password
                </Button>

              </View>

            </Fragment>
          )}
        </Formik>

      </Surface>

    </ScreenContainerView>
  );
};

const styles = StyleSheet.create({
  fieldContainer: {
    width: '100%',
    marginVertical: 12,
  },
  error: {
    fontSize: 14,
    paddingHorizontal: 4,
    paddingTop: 4,
    color: 'red'
  },
  buttonContainer: {
    maxWidth: 600
  },
  surfaceContainer: {
    flex: 1,
    padding: Platform.OS === 'web' ? 30 : 10
  }
});
