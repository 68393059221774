import React, { memo } from 'react';
import {StyleSheet, View} from 'react-native';
import { useTheme, Text } from 'react-native-paper';

type Props = {
  text: string;
  textSize?: number;
  color?: string;
  thickness?: number;
};

const Divider = ({ text='', textSize=18, color='lightgray', thickness=1 }: Props) => {

  const theme = useTheme();

  return (
    <View style={ styles.container }>
      <View style={[styles.horizontalRule, { backgroundColor: color, height: thickness }]} />
      { text.length > 0 &&
      <>
        <View>
          <Text style={[styles.text, { color, fontSize: textSize }]}>{text}</Text>
        </View>
        <View style={[styles.horizontalRule, { backgroundColor: color, height: thickness }]} />
      </>
      }
    </View>
  );

};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20
  },
  horizontalRule: {
    flex: 1,
    height: 2,
    backgroundColor: 'black'
  },
  text: {
    width: 50,
    textAlign: 'center',
    fontWeight: 'bold'
  }
});

export default memo(Divider);
