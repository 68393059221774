/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import {useTheme} from 'react-native-paper';

import NotFoundScreen from '../screens/public/NotFoundScreen';

import { RootStackParamList } from '../../types';
import LinkingConfiguration from './LinkingConfiguration';
import {useAuthStore} from "../store/auth/authStore";
import {useDataStore} from "../store/data/dataStore";
import LoginScreen from "../screens/public/LoginScreen";
import ForgotPasswordScreen from "../screens/public/ForgotPasswordScreen";
import Slide1 from "../screens/public/Slide1";
import Slide2 from "../screens/public/Slide2";
import Slide3 from "../screens/public/Slide3";
import {AppDrawerNavigator} from "./AppDrawerNavigator";
import AdminWebContainer from "../screens/app/admin/AdminWebContainer";
import {useEffect, useState} from "react";
import KeyStoreService from "../services/KeyStoreService";
import {Platform, View} from "react-native";
import {navigationRef} from '../services/NavigationService';

export function Navigation() {

  // theme
  const theme = useTheme();
  const navigationTheme = theme.dark ? DarkTheme : DefaultTheme;

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={navigationTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {

  const theme = useTheme();

  const [navIsReady, setNavIsReady] = useState(false);
  const { isShowSplash, isShowSlideshow, isShowLogin, isShowVerification, isAuthenticated, isSignout, showLogin, showSlideshow } = useAuthStore();
  const { trackingUri, setDelayedNav } = useDataStore();

  /*
  console.log('----------------------------------------------');
  console.log('isShowSplash', isShowSplash);
  console.log('isShowSlideshow', isShowSlideshow);
  console.log('isShowLogin', isShowLogin);
  console.log('isShowVerification', isShowVerification);
  console.log('isAuthenticated', isAuthenticated);
  console.log('isSignout', isSignout);
   */

  useEffect(() => {
    console.log('Loading[Navigation]');

    KeyStoreService.getShowSlideshow().then(
      (show) => {

        if (show && Platform.OS !== 'web') {
          console.log('Call showSlideshow()');
          showSlideshow();
        }
        else {
          console.log('Call showLogin()');
          showLogin();
        }

        setNavIsReady(true);

      }
    );

  }, []);

  if (!navIsReady) {
    return (
      <View style={{width: '100%', height: '100%', backgroundColor: Platform.OS == 'web' ? theme.colors.tocBackground : '#00A7F7' }} />
    );
  }

  return (
    <Stack.Navigator>
      { isShowSlideshow == true ? (
          <>
            <Stack.Screen name="Slide1" component={Slide1} options={{
              headerShown: false,
              animationTypeForReplace: isSignout ? 'pop' : 'push',
            }}/>
            <Stack.Screen name="Slide2" component={Slide2} options={{headerShown: false}}/>
            <Stack.Screen name="Slide3" component={Slide3} options={{headerShown: false}}/>
          </>

        ) : isShowLogin == true ? (
          <>
            <Stack.Screen name="LoginScreen" component={LoginScreen} options={{
              headerShown: false,
              animationTypeForReplace: isSignout ? 'pop' : 'push',
            }}/>
            <Stack.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} options={{headerShown: false}}/>
            <Stack.Screen name="AdminWebContainer" component={AdminWebContainer} options={{title: trackingUri }} />
          </>

        ) : isAuthenticated == true ? (
          <Stack.Screen name="AppDrawerNavigator" component={AppDrawerNavigator} options={{headerShown: false}}/>

        ) : (
          <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Oops!'}}/>

        )
      }
    </Stack.Navigator>
  );
}