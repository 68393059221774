import React, {useReducer, useContext, ReactNode} from 'react';

import {initialState, reducer} from './dataStoreReducer';

import {DataContextType} from './dataStoreTypes';

const DataContext = React.createContext<DataContextType>({
  setUserProfile: (data) => {},
  setUpgrade: (data) => {},
  setFocused: (data) => {},
  setDelayedNav: (data) => {},
  setWebContainerUrl: (data) => {},
  setWebContainerLoggedIn: (data) => {},
  setTrackingUri: (data) => {},
  setTrackingMenuId: (data) => {},
  setTargetUri: (data) => {},
  logout: (data) => {},
  ...initialState
});

export const useDataStore = () => {
  return useContext(DataContext);
};

type Props = {
  children: ReactNode
};

export const DataProvider = ({ children }: Props) => {

  const [contextValue, dispatch] = useReducer(reducer, initialState);

  contextValue.getInitialConfig = () => dispatch({ type: 'GET_INITIAL_CONFIG' });

  contextValue.setUserProfile = async (data: any) => {
    //console.log('dataStore dispatch USER_PROFILE begin', data);
    dispatch({ type: 'USER_PROFILE', payload: data });
    //console.log('dataStore dispatch USER_PROFILE end', data);
  };

  contextValue.setUpgrade = async (data: any) => {
    dispatch({ type: 'SET_UPGRADE', payload: data });
  };

  contextValue.setFocused = async (data: any) => {
    dispatch({ type: 'SET_FOCUSED', payload: data });
  };

  contextValue.setDelayedNav = async (data: any) => {
    dispatch({ type: 'SET_DELAYED_NAV', payload: data });
  };

  contextValue.setWebContainerUrl = async (data: any) => {
    dispatch({ type: 'SET_WEB_CONTAINER_URL', payload: data });
  };

  contextValue.setWebContainerLoggedIn = async (data: boolean) => {
    dispatch({ type: 'SET_WEB_CONTAINER_LOGGED_IN', payload: data });
  };

  contextValue.setTrackingUri = async (data: any) => {
    dispatch({ type: 'SET_TRACKING_URI', payload: data });
  };

  contextValue.setTrackingMenuId = async (data: any) => {
    dispatch({ type: 'SET_TRACKING_MENU_ID', payload: data });
  };

  contextValue.setTargetUri = async (data: any) => {
    dispatch({ type: 'SET_TARGET_URI', payload: data });
  };

  contextValue.logout = async (data: any) => {
    dispatch({ type: 'LOGOUT', payload: data });
  };

  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );

};
