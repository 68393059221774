import {StyleSheet, TouchableOpacity, View} from 'react-native';
import { Text } from 'react-native-paper';
import { RootStackScreenProps } from '../../../types';
import {useAuthStore} from "../../store/auth/authStore";
import {useEffect} from "react";

export default function NotFoundScreen({ navigation }: RootStackScreenProps<'NotFound'>) {

  /*
  const { isShowSplash, isShowSlideshow, isShowLogin, isShowVerification, isAuthenticated, isSignout } = useAuthStore();

  console.log('----------------------------------------------');
  console.log('isShowSplash', isShowSplash);
  console.log('isShowSlideshow', isShowSlideshow);
  console.log('isShowLogin', isShowLogin);
  console.log('isShowVerification', isShowVerification);
  console.log('isAuthenticated', isAuthenticated);
  console.log('isSignout', isSignout);
   */

  useEffect(() => {
    console.log('Loading[NotFoundScreen]');
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>This screen doesn't exist.</Text>
      <TouchableOpacity onPress={() => navigation.replace('Root')} style={styles.link}>
        <Text style={styles.linkText}>Go to home screen!</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
