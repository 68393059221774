import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Switch, Text, TouchableRipple, useTheme} from 'react-native-paper';
import {RootTabScreenProps} from "../../../../types";
import React, {useEffect} from "react";
import {PreferencesContext} from "../../../context/preferencesContext";
import {DrawerActions, useIsFocused} from "@react-navigation/native";
import {useDataStore} from "../../../store/data/dataStore";

export default function EmployeeCalendarScreen({ navigation }: RootTabScreenProps<'EmployeeCalendarScreen'>) {

  const theme = useTheme();
  const { theme: selectedTheme, toggleTheme } = React.useContext( PreferencesContext );
  const {setTrackingMenuId} = useDataStore();

  const isFocused = useIsFocused();

  useEffect(() => {
    console.log('EmployeeCalendarScreen[Loading]');
    if (isFocused) {
      console.log('EmployeeCalendarScreen[Focused]');
      setTrackingMenuId('mytimeoff-calendar');
    }

    return () => {
      console.log('EmployeeCalendarScreen[Unloading]');
    }

  }, [isFocused]);

  const _onOpenSidebarPressed = () => {
    navigation.dispatch(DrawerActions.openDrawer());
  }

  const _onSelectTheme = async () => {
    console.log('EmployeeCalendarScreen::_onSelectTheme');
    toggleTheme();
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Calendar</Text>

      <TouchableOpacity onPress={_onOpenSidebarPressed} style={{ paddingTop: 30, paddingBottom: 30 }}>
        <Text>Open Sidebar</Text>
      </TouchableOpacity>

      <TouchableRipple onPress={_onSelectTheme} >
        <View style={styles.preference}>
          <Text style={styles.preferenceText }>Dark Theme</Text>
          <View pointerEvents="none">
            <Switch
              ios_backgroundColor="red"
              trackColor={{true: 'green', false: 'red'}}
              value={(selectedTheme == 'dark' ? true : false)}
            />
          </View>
        </View>
      </TouchableRipple>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  preference: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  preferenceText: {
    paddingRight: 15
  }

});
