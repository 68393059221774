import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Text, Title, useTheme} from 'react-native-paper';
import { RootStackScreenProps } from '../../../types';
import {useAuthStore} from "../../store/auth/authStore";
import KeyStoreService from "../../services/KeyStoreService";
import React, {useEffect, useRef} from "react";
import LottieView from "lottie-react-native";

export default function Slide3({ navigation }: RootStackScreenProps<'Slide3'>) {

  const theme = useTheme();

  const {showLogin} = useAuthStore();

  const animation = useRef(null);

  useEffect(() => {
    console.log('Loading[Slide3]');
  }, []);

  const _onBackPressed = () => {
    navigation.goBack();
  }

  const _onForwardPressed = () => {

    KeyStoreService.setShowSlideshow(false);

    showLogin();
  }

  return (
    <View style={[styles.container, {backgroundColor: theme.colors.tocBlue}]}>
      <Title style={{color: 'white'}}>Take Time Off</Title>

      <LottieView
        autoPlay
        ref={animation}
        style={{
          width: 300,
          height: 300,
          backgroundColor: theme.colors.tocBlue,
        }}
        // Find more Lottie files at https://lottiefiles.com/featured
        sourceX={require('../../../assets/lottie/100461-working-calendar-task-table-notification.json')}
        source={require('../../../assets/lottie/92578-check-okey-done.json')}
        sourceX={require('../../../assets/lottie/94350-gears-lottie-animation.json')}
      />

      <TouchableOpacity onPress={_onForwardPressed} style={{ paddingTop: 30 }}>
        <Text>Go Forward</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={_onBackPressed} style={{ paddingTop: 30 }}>
        <Text>Go Back</Text>
      </TouchableOpacity>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
