import React, { memo } from 'react';
import {Image, StyleSheet, View} from 'react-native';
import { useTheme } from 'react-native-paper';

type Props = {
  dark?: boolean;
  width?: number;
  height?: number;
};

const LogoAndWordmark = ({dark = true, width = 320, height = 60 }: Props) => {

  const theme = useTheme();

  const logo = require('../../assets/images/logowordmark.png');
  const logoWhite = require("../../assets/images/logowordmarkwhite.png");

  return (
    <Image
      style={[styles.image, { width, height } ]}
      source={(dark ? logo : logoWhite)}
    />
  );

};

const styles = StyleSheet.create({
  image: {
    marginTop: 50,
    marginBottom: 12,
    resizeMode: 'contain'
  },
});

export default memo(LogoAndWordmark);
