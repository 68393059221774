import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
//import {StackNavigatorParamlist} from '../types';
import ModalScreen from "../screens/app/ModalScreen";
import {BottomTabNavigator} from "./BottomTabNavigator";
import AdminWebContainer from "../screens/app/admin/AdminWebContainer";
import {Pressable} from "react-native";
import {FontAwesome} from "@expo/vector-icons";

import {useDataStore} from "../store/data/dataStore";
import Header from "../components/Header";
import Home from "../screens/app/Home";
import Profile from "../screens/app/profile/Profile";
import About from "../screens/app/about/About";
import Licensing from "../screens/app/about/Licensing";
import ChangePassword from "../screens/app/profile/ChangePassword";
import ChangeUsername from "../screens/app/profile/ChangeUsername";
import AuthService from "../services/AuthService";
import {useAuthStore} from "../store/auth/authStore";
import {Auth} from "aws-amplify";

//const Stack = createStackNavigator<StackNavigatorParamlist>();
const Stack = createNativeStackNavigator();

export const AppStackNavigator = () => {

  const {signOut} = useAuthStore();

  const { trackingUri, setTargetUri, userProfile } = useDataStore();

  const userInitialRouteName = (userProfile.isEmployee ? 'MyTimeOff' : 'AdminWebContainer');

  /* *JEC* Need to make the Stack.Screen Root headerShown = false and then
    difine the menu in the tabs if we want different header
    */

  const _onLogoutPressed = async () => {
    await AuthService.logout();
    signOut();
  }

  return (
    <Stack.Navigator
      initialRouteName={userInitialRouteName}
      screenOptions={{
        headerShown: true,
        header: props => <Header {...props} />
      }}
    >
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="MyTimeOff" component={BottomTabNavigator} options={{ headerShown: true }} />
      <Stack.Group screenOptions={{presentation: 'modal'}}>
        <Stack.Screen name="Modal" component={ModalScreen}/>
      </Stack.Group>
      <Stack.Screen name="AdminWebContainer" component={AdminWebContainer} options={
        {
          title: trackingUri,
          headerRight: () => (
            <Pressable
              /* onPress={() => { setTargetUri('https://portal-staging.timeoffcloud.net/timeoff/logout/logouttenant') }} */
              onPress={_onLogoutPressed}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="info-circle"
                size={25}
                color={'red'}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ),
        }} />
      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="Licensing" component={Licensing} />
      <Stack.Screen name="ChangePassword" component={ChangePassword} />
      <Stack.Screen name="ChangeUsername" component={ChangeUsername} />
    </Stack.Navigator>
  );
};
